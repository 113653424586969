import React from "react";
import { Link } from "react-router-dom";

import "./ObrasRealizadas.scss";

const ObrasRealizadaThumb = ({ obraNombre, imgUrl, linkUrl }) => {
    return (
        <div class="col-md-2 col-4 obra__item">
            <img
                src={imgUrl}
                class="obras__realizadas"
                alt={obraNombre}
            />
            <Link to={linkUrl}>
                <div class="background-hover">
                    <p class="obra__item--text">{obraNombre}</p>
                    <button class="buttonContainer" type="button">
                        Ver más
                    </button>
                </div>
            </Link>
        </div>
    );
};

export default ObrasRealizadaThumb;
