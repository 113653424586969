import React from 'react'
import DivisionBanner from './DivisionBanner/DivisionBanner'
import './DivisionContainer.scss'

export const DivisionContainer = (props) => {
 
    return (

        <div className='container-fluid p-0 mb-4 divisionContainer'>
            <h1 className='division__title' >
                <i className="fas fa-angle-double-down" style={{'paddingBottom':'15px', 'width' : '100%'}} ></i><br />
                Divisiones
            </h1>
            <DivisionBanner 
                division={'Construcción y reparación de Tanques'} 
                imgUrl = {'../images/home/reparacion-de-tanques.jpg'}
                linkUrl= {'/construccion-reparacion-tanques/'}
            />
            <DivisionBanner 
                division={'Armado de Andamios'} 
                imgUrl = {'../images/home/armado-de-andamios.jpg'}
                linkUrl= {'/armado-andamios/'}
            />
            <DivisionBanner 
                division={'Aislaciones termicas'} 
                imgUrl = {'../images/home/aislaciones-termicas.jpg'} 
                linkUrl= {'/aislaciones-termicas/'}
            />
            <DivisionBanner 
                division={'Radiadiores'} 
                imgUrl = {'../images/home/radiadores.jpg'} 
                linkUrl= {'/radiadores/'}
            />
            <DivisionBanner 
                division={'Obras civiles'} 
                imgUrl = {'../images/home/obras-civiles.jpg'} 
                linkUrl= {'/obras-civiles-industriales/'}
            />
        </div>

    )
}

export default DivisionContainer