import React, { useState, useEffect } from 'react'
import Loading from '../Commons/Loading/Loading';
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import DivisionBanner from '../DivisionContainer/DivisionBanner/DivisionBanner';
import { Modal } from 'react-bootstrap'

import '../../Styles/main.scss'
import '../Divisiones/DivisionesDetalles.scss'
import ObrasRealizadas from '../Commons/ObrasRealizadas/ObrasRealizadas';

const CapsaCapex = () => {


    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = (paramUrl, paramDesc) => {

        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)

        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    })

    if (loading) { return <Loading /> }

    return (

        <section className='container-fluid p-0 containerBlock' >


            <Modal
                show={show}
                size='lg'
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Aislación de Piu.</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
                </Modal.Body>
                {/* 
              <Modal.Footer>
                <small >{txtDes}</small>
              </Modal.Footer> */}

            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-aislaciones-termicas.jpg"
                    alt="Obras, andamios."
                    loading='lazy'
                    className='divisionItem__container--img'
                />

                <div className='divisionItem__container--textos divisionItem__container--textos-obras' >
                    <h3 className='divisionItem__container--header'>OBRAS</h3>
                    <h1 className='divisionItem__container--title'>Capsa/Capex</h1>
                </div>

            </div>

            <div className='container-fluid divisionItem__detail'>
                <div className='container animated fast fadeIn'>
                    <Link to={'/obras/'}>
                        <small>
                            <i className="fas fa-angle-double-left" style={{ 'paddingBottom': '15px', 'paddingRight': '10px' }} ></i>
                            Volver a Obras
                        </small>
                    </Link>

                    <div className='row'>

                        <div className='col-md-4'>
                            <img
                                src="../images/obras/capsa-capex/capsa-capex-04.jpg"
                                alt="Aislación de piping en PIUS."
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            />
                        </div>

                        <div className='col-md-8 divisionItem__detail--block'>
                            <h1 className='divisionItem__detail--title' >Mantenimiento de Red contra Incendio.</h1>
                            <p className='divisionItem__detail--text' >
                                <ul>
                                    <li>Provisión y armado de andamios para trabajos sobre Anillos de riego y cámara de espuma.</li>
                                    <li>Limpieza y emplace de válvulas dosificadoras, válvulas esféricas y tubos pescadores.</li>
                                    <li>Desmontaje y limpieza para hidrantes y reemplazo de mangueras.</li>
                                    <li>Colocación de lanzas</li>
                                    <li>Realización de Ensayos no Destructivos, Pruebas hidráulicas, ensayos para espuma como Tiempo de drenaje, Relación de expansión, Porcentaje de dosificación.</li>
                                    <li>Colocación de nichos de mangueras.</li>
                                    <li>Bridar la red contra incendio (RCI) y desarrollo de carreteles.</li>
                                    <li>Mantenimiento de monitores.</li>
                                    <li>Colocación de aspersores sprinkler. </li>
                                    <li>Varios: señalización, bases de hormigón, pintura. </li>
                                </ul>
                            </p>
                            <br /><br />
                            <h1 className='divisionItem__detail--title' >Obra de mantenimiento de red contra incendio</h1>
                            <h3 className='divisionItem__detail--subtitle' >
                                <i className="fas fa-map-marker-alt" style={{ 'paddingBottom': '5px', 'paddingRight': '10px' }}></i>
                                Pampa del castillo - Planta Sur - Planta Central
                            </h3>
                            <p className='divisionItem__detail--text' >
                                Realizamos la reparación y mantenimiento de redes contra incendio donde principalmente nuestro objetivo
                                es otorgar la seguridad de que todo el circuito de urgencias ante un siniestro funcione con total eficacia.
                                Tanto sea reducir, controlar o mitigar los efectos del fuego. Nuestro servicio cumple las normas IRAM 3546-3549-3594-3619.<br />
                                Personal capacitado para la tarea, equipamiento acorde y certificado, inspección e informes certificados.
                            </p>
                            <p className='divisionItem__detail--text' >
                                <ul>
                                    <li>Limpieza y emplace de válvulas dosificadoras, válvulas esféricas y tubos pescadores.</li>
                                    <li>Prueba hidráulica certificada.</li>
                                    <li>Mantenimiento: monitores, aspersores, señalización, pintura, herrajes, nichos, ect.</li>
                                </ul>
                            </p>


                        </div>

                    </div>

                    <div className='gallery__cont'>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/capsa-capex/capsa-capex-01.jpg', 'Capsa Capex.')} src='../images/obras/capsa-capex/capsa-capex-01.jpg' alt='aislacion lh3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/capsa-capex/capsa-capex-02.jpg', 'Capsa Capex.')} src='../images/obras/capsa-capex/capsa-capex-02.jpg' alt='aislacion lh3' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/capsa-capex/capsa-capex-05.jpg', 'Capsa Capex.')} src='../images/obras/capsa-capex/capsa-capex-05.jpg' alt='aislacion lh3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/capsa-capex/capsa-capex-01.jpg', 'Capsa Capex.')} src='../images/obras/capsa-capex/capsa-capex-03.jpg' alt='aislacion lh3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/capsa-capex/capsa-capex-07.jpg', 'Capsa Capex.')} src='../images/obras/capsa-capex/capsa-capex-07.jpg' alt='aislacion lh3' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/capsa-capex/capsa-capex-04.jpg', 'Capsa Capex.')} src='../images/obras/capsa-capex/capsa-capex-04.jpg' alt='aislacion lh3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/capsa-capex/capsa-capex-06.jpg', 'Capsa Capex.')} src='../images/obras/capsa-capex/capsa-capex-06.jpg' alt='aislacion lh3' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/capsa-capex/capsa-capex-08.jpg', 'Capsa Capex.')} src='../images/obras/capsa-capex/capsa-capex-08.jpg' alt='aislacion lh3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/capsa-capex/capsa-capex-09.jpg', 'Capsa Capex.')} src='../images/obras/capsa-capex/capsa-capex-09.jpg' alt='aislacion lh3' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/capsa-capex/capsa-capex-10.jpg', 'Capsa Capex.')} src='../images/obras/capsa-capex/capsa-capex-10.jpg' alt='aislacion lh3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/capsa-capex/capsa-capex-11.jpg', 'Capsa Capex.')} src='../images/obras/capsa-capex/capsa-capex-11.jpg' alt='aislacion lh3' loading='lazy' />
                        </div>

                    </div>
                </div>
            </div>
            <div>
                <ObrasRealizadas cantObras={5} excluir={8} />
            </div>

            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default CapsaCapex