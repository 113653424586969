import React, {useState, useEffect} from 'react'
import Loading from '../Commons/Loading/Loading';
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import '../Divisiones/DivisionesDetalles.scss'
import DivisionBanner from '../DivisionContainer/DivisionBanner/DivisionBanner';
import { Modal } from 'react-bootstrap'
import ObraThumb from '../Commons/ObraThumb/ObraThumb';
import ObrasRealizadas from '../Commons/ObrasRealizadas/ObrasRealizadas';


const PicoDeAgua = () => {


    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (paramUrl, paramDesc) => {
        
        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)
        
        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return (

        <section className='container-fluid p-0 containerBlock' > 


            <Modal
              show={show}
              size='lg'
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Red Metropolitana de Fibra Óptica</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
              </Modal.Body>
            
            {/* 
              <Modal.Footer>
                <small >{txtDes}</small>
              </Modal.Footer> 
            */}

            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-telecomunicaciones.jpg" 
                    alt="Obras, andamios." 
                    loading='lazy'
                    className='divisionItem__container--img'    
                />

                <div className='divisionItem__container--textos divisionItem__container--textos-obras' >
                    <h3 className='divisionItem__container--header'>OBRAS</h3>
                    <h1 className='divisionItem__container--title divisionItem__container--title--telecomunicaciones'>TELECOMUNICACIONES</h1>
                </div>

            </div>

            <div className='container-fluid obrasItem__detail'>
                <div className='container animated fast fadeIn'>
                    <Link to={'/obras/'}>
                        <small> 
                        <i className="fas fa-angle-double-left" style={{'paddingBottom':'15px', 'paddingRight': '10px'}} ></i> 
                        Volver a Obras
                        </small>
                    </Link>

                    <div className='row'>

                        <div className='col-md-5'>     
                            <img 
                                src="../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-15.jpg" 
                                alt="Trabajo en la red Metropolitana de Fibra Optica de Agua."
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            /> 
                        </div>

                        <div className='col-md-7 divisionItem__detail--block'>
                            <h1 className='divisionItem__detail--title' >Red DWDM Gran Ancho de Banda FO Zero Pico de Agua.</h1>
                            
                            <p  className='divisionItem__detail--text pt-2' > 
                            Obra realizada para la Red Metropolitana de Fibra Óptica, de la Dirección Provincial de 
                            Tecnología de la Pcia de Bs As.<br /><br />
                            Se realizo el tendido de 84 km de FO para conectar 129 edificios Gubernamentales en DWDM.
                            <br />
                            </p>

                            <div className='row obras__box justify-content-center' style={{ marginTop: '40px'}}>
                                <div className='col-6 col-sm-4 col-md-6 col-lg-4 obras__card'>
                                    <ObraThumb  
                                        ObraNum  = {'129'}
                                        ObraVal  = {''}
                                        ObraItem = {''}
                                        ObraDet  = {'Edificios conectados.'}
                                    />
                                </div>
                                <div className='col-6 col-sm-4 col-md-6 col-lg-4 obras__card'>
                                    <ObraThumb  
                                        ObraNum  = {'84'}
                                        ObraVal ={'KM'}
                                        ObraItem = {''}
                                        ObraDet  = {'de FO en tendido por ducto.'}
                                    />
                                </div>
                                <div className='col-6 col-sm-4 col-md-6 col-lg-4 obras__card'>
                                    <ObraThumb  
                                        ObraNum  = {'16'}
                                        ObraVal ={'TB'}
                                        ObraItem = {''}
                                        ObraDet  = {'Enlaces de 16 TB por segundo.'}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                    
                    
                    <div className='gallery__cont'>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-01.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-01.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy'/>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-02.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-02.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-03.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-03.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-04.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-04.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-05.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-05.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-06.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-06.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-07.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-07.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                           
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-08.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-08.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-09.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-09.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-10.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-10.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-11.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-11.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-12.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-12.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' /> 
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-13.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-13.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy'/>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-14.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-14.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy'/>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-16.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-16.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy'/>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-17.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-17.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-18.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-18.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                          
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-15.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-15.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy'/>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-19.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-19.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-20.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-20.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-21.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-21.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-22.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-22.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-24.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-24.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' /> 
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-23.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-23.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-25.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-25.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-26.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-26.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-28.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-28.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-29.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-29.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-27.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/pico-de-agua-27.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' /> 
                        </div>

                    </div>
                </div>
            </div>
            <div className='otrasObras__container'>
                <ObrasRealizadas cantObras={6} />
            </div>

            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default PicoDeAgua