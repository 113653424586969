import React from 'react'
import DivisionBanner from './DivisionBanner/DivisionBanner'
import './DivisionContainer.scss'

export const DivisionContainerInternas = (props) => {
 
    const divSelectNotShow = props.divSelect;

    return (

        <div className='container-fluid p-0 mb-4 divisionContainer divisionContainerInternas'>
        
            { divSelectNotShow === 'reparacion-tanques' ? '' : <DivisionBanner division={'Construcción y reparación de Tanques'} imgUrl = {'../images/home/reparacion-de-tanques.jpg'} linkUrl= {'/construccion-reparacion-tanques/'}/> }       
            { divSelectNotShow === 'armado-andamios' ? '' : <DivisionBanner division={'Armado de Andamios'} imgUrl = {'../images/home/armado-de-andamios.jpg'} linkUrl= {'/armado-andamios/'}/> }   
            { divSelectNotShow === 'aislaciones-termicas' ? '' : <DivisionBanner division={'Aislaciones termicas'} imgUrl = {'../images/home/aislaciones-termicas.jpg'} linkUrl= {'/aislaciones-termicas/'}/> }   
            { divSelectNotShow === 'radiadores' ? '' : <DivisionBanner division={'Radiadiores'} imgUrl = {'../images/home/radiadores.jpg'}linkUrl= {'/radiadores/'}/> }   
            { divSelectNotShow === 'obras-civiles' ? '' : <DivisionBanner division={'Obras civiles industriales'} imgUrl = {'../images/home/obras-civiles.jpg'} linkUrl= {'/obras-civiles-industriales/'}/> }   
    
        </div>

    )
}

export default DivisionContainerInternas