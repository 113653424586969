import React, {useState, useEffect} from 'react'
import Loading from '../../Commons/Loading/Loading';
import FooterFormulario from '../../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import DivisionBanner from '../../DivisionContainer/DivisionBanner/DivisionBanner';
import { Modal } from 'react-bootstrap'

import '../../../Styles/main.scss'
import '../DivisionesDetalles.scss'

const PisoReparacionConstruccion = () => {

    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (paramUrl, paramDesc) => {
        
        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)
        
        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return (

        <section className='container-fluid p-0 containerBlock' > 


            <Modal
              show={show}
              size='lg'
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Piso: Reparación y construcción</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
              </Modal.Body>

              <Modal.Footer>
                <small >{txtDes}</small>
              </Modal.Footer>
            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-reparacion-de-tanques.jpg" 
                    alt="Reparacion de Tanques." 
                    loading='lazy'
                    className='divisionItem__container--img'    
                />

                <div className='divisionItem__container--textos divisionItem__container--textos-2-lineas' >
                    <h3 className='divisionItem__container--header'>DIVISION</h3>
                    <h1 className='divisionItem__container--title'>CONSTRUCCION Y REPARACION DE TANQUES</h1>
                </div>

            </div>
            <div className='container-fluid divisionItem__detail'>
                <div className='container animated fast fadeIn'>
                    <Link to={'/construccion-reparacion-tanques/'}>
                    <small> 
                        <i className="fas fa-angle-double-left" style={{'paddingBottom':'15px', 'paddingRight': '10px'}} ></i> 
                        Volver a Division</small>
                    </Link>

                    <div className='row'>
                        <div className='col-md-6'> 
                            
                            <img 
                                src="../images/divisiones/construccion-reparacion-tanques/piso-reparacion-construccion/piso-reparacion-01.jpg" 
                                alt="Reparacion de piso-reparacion de tanque, obra terminada."
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            /> 
                        </div>

                        <div className='col-md-6 divisionItem__detail--block'>
                            <h1 className='divisionItem__detail--title' >Piso: Reparación y construcción</h1>
                            <p  className='divisionItem__detail--text' > 
                            Se realizan reparaciones o reemplazo completo de chapas de piso de tanques.<br /><br />Dependiendo de la inspección y determinación de corrosión, agrietamientos, sobrecarga, etc. Se diseña, se construye y se monta chapas de piso bajo normas API 652.<br /><br />También se hacen reparaciones con PRFV, parches, soldaduras manuales o automáticas. Todos los trabajos de soldadura son inspeccionados con ensayos no destructivos garantizando los procesos utilizados. 
                            </p> 
                            
                        </div>

                    </div>

                    <div className='gallery__cont'>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/construccion-reparacion-tanques/piso-reparacion-construccion/piso-reparacion-01.jpg', 'Descripcion de la foto.') } src='../images/divisiones/construccion-reparacion-tanques/piso-reparacion-construccion/piso-reparacion-01.jpg' alt='Reparacion de piso' loading='lazy'/>
                            <img    onClick={ () => handleShow('../images/divisiones/construccion-reparacion-tanques/piso-reparacion-construccion/piso-reparacion-02.jpg', 'Descripcion de la foto.') } src='../images/divisiones/construccion-reparacion-tanques/piso-reparacion-construccion/piso-reparacion-02.jpg' alt='Reparacion de piso' loading='lazy' />
                            
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/construccion-reparacion-tanques/piso-reparacion-construccion/piso-reparacion-04.jpg', 'Descripcion de la foto.') } src='../images/divisiones/construccion-reparacion-tanques/piso-reparacion-construccion/piso-reparacion-04.jpg' alt='Reparacion de piso' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/construccion-reparacion-tanques/piso-reparacion-construccion/piso-reparacion-05.jpg', 'Descripcion de la foto.') } src='../images/divisiones/construccion-reparacion-tanques/piso-reparacion-construccion/piso-reparacion-05.jpg' alt='Reparacion de piso' loading='lazy' />
                        
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/construccion-reparacion-tanques/piso-reparacion-construccion/piso-reparacion-10.jpg', 'Descripcion de la foto.') } src='../images/divisiones/construccion-reparacion-tanques/piso-reparacion-construccion/piso-reparacion-10.jpg' alt='Reparacion de piso' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/construccion-reparacion-tanques/piso-reparacion-construccion/piso-reparacion-08.jpg', 'Descripcion de la foto.') } src='../images/divisiones/construccion-reparacion-tanques/piso-reparacion-construccion/piso-reparacion-08.jpg' alt='Reparacion de piso' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/construccion-reparacion-tanques/piso-reparacion-construccion/piso-reparacion-01.jpg', 'Descripcion de la foto.') } src='../images/divisiones/construccion-reparacion-tanques/piso-reparacion-construccion/piso-reparacion-03.jpg' alt='Reparacion de piso' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/construccion-reparacion-tanques/piso-reparacion-construccion/piso-reparacion-07.jpg', 'Descripcion de la foto.') } src='../images/divisiones/construccion-reparacion-tanques/piso-reparacion-construccion/piso-reparacion-07.jpg' alt='Reparacion de piso' loading='lazy' />
                            
                        
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/construccion-reparacion-tanques/piso-reparacion-construccion/piso-reparacion-06.jpg', 'Descripcion de la foto.') } src='../images/divisiones/construccion-reparacion-tanques/piso-reparacion-construccion/piso-reparacion-06.jpg' alt='Reparacion de piso' loading='lazy' /> 
                            
                            <img    onClick={ () => handleShow('../images/divisiones/construccion-reparacion-tanques/piso-reparacion-construccion/piso-reparacion-09.jpg', 'Descripcion de la foto.') }
                                    src='../images/divisiones/construccion-reparacion-tanques/piso-reparacion-construccion/piso-reparacion-09.jpg' alt='Reparacion de piso' loading='lazy' />
                        </div> 
                    
                    </div>
                </div>
                </div>
            <div>

                <h3 className='divisionItem__bajada--tit text-center'>
                   Otras Divisiones
                </h3>

                <div className='row divisionItem__proyectosDestacados p-0'> 
                    {/* <DivisionBanner 
                        division = {'piso-reparacion: Reparación y Construcción'}
                        imgUrl = {'../images/divisiones/division-banners/divisionBanners-piso-reparacion-reparacion.jpg'}
                        linkUrl = {'/construccion-reparacion-tanques/piso-reparacion-reparacion-y-construccion/'}
                    />  */}
                    <DivisionBanner 
                        division = {'Arenado y pintura'}
                        imgUrl = {'../images/divisiones/division-banners/divisionBanners-arenado-y-pintura.jpg'}
                        linkUrl = {'/construccion-reparacion-tanques/arenado-pintura/'}
                    /> 
                    <DivisionBanner 
                        division = {'techo-reparacion: Reparación y Construcción'}
                        imgUrl = {'../images/divisiones/division-banners/divisionBanners-techo-reparacion.jpg'}
                        linkUrl = {'/construccion-reparacion-tanques/techo-reparacion-reparacion-y-construccion/'}
                    /> 
                    <DivisionBanner 
                        division = {'Still Pipe'}
                        imgUrl = {'../images/divisiones/division-banners/divisionBanners-still-pipe.jpg'}
                        linkUrl = {'/construccion-reparacion-tanques/still-pipe/'}
                    /> 
                    <DivisionBanner 
                        division = {'P.E.A.D'}
                        imgUrl = {'../images/divisiones/division-banners/divisionBanners-pead.jpg'}
                        linkUrl = {'/construccion-reparacion-tanques/pead/'}
                    /> 
                </div>

            </div>

            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default PisoReparacionConstruccion