export const OBRAS = [


  // Line 1
  {
    id: 0,
    obraNombre: "Reparación de Tanque 235 m³",
    imgUrl: "../images/obras/tanque-tk-tp35/tanque-tk-tp35-00.jpg",
    linkUrl: "/obras/reparacion-tanque-35/",
  },
  {
    id: 1,
    obraNombre: "Reparación de Tanque 160 m³",
    imgUrl: "../images/obras/tanque-tk-tp20/tanque-tk-tp20-01.jpg",
    linkUrl: "/obras/reparacion-tanque-160/",
  },
  {
    id: 2,
    obraNombre: "Fabricacion de Tanque 320m3",
    imgUrl: "../images/obras/tanque-320m3/tanque-320m3-10.jpg",
    linkUrl: "/obras/construccion-tanque-320/",
  },
  {
    id: 3,
    obraNombre: "Construcción de Tanque Skimmer 2548 m³",
    imgUrl: "../images/obras/tanque-tk-471b/tanque-tk-471b-18.jpg",
    linkUrl: "/obras/construccion-tanque-471b/",
  },


  // line 2
  {
    id: 4,
    obraNombre: "Aislacion de LH03",
    imgUrl: "../images/obras/aislacion-lh3/aislacion-lh3.jpg",
    linkUrl: "/obras/aislacion-de-lh03/",
  },
  {
    id: 5,
    obraNombre: "Aislacion en piu",
    imgUrl: "../images/obras/aislacion-piu/aislacion-piu-01.jpg",
    linkUrl: "/obras/aislacion-en-piu/",
  },
  {
    id: 6,
    obraNombre: "Spool",
    imgUrl: "../images/obras/spool/spool-00.jpg",
    linkUrl: "/obras/spool/",
  },


  // Line 3
  {
    id: 7,
    obraNombre: "Radiador Guascor FG240",
    imgUrl: "../images/obras/radiador-guascor/radiador-guascor.jpg",
    linkUrl: "/obras/radiador-guascor-fg-24/",
  },

  {
    id: 8,
    obraNombre: "Mantenimiento RCI",
    imgUrl: "../images/obras/capsa-capex/capsa-capex-00.jpg",
    linkUrl: "//obras/capsa-capex/",
  },
  
  {
    id: 9,
    obraNombre: "Andamio > 20 metros de altura",
    imgUrl: "../images/obras/andamios-20m/andamio.jpg",
    linkUrl: "/obras/andamio-20m",
  },
 


  // Line 4
  {
    id: 10,
    obraNombre: "Obra Civil Antares³",
    imgUrl: "../images/obras/obra-civil-antares/antares-02.jpg",
    linkUrl: "/obras/obra-civil-antares/",
  },
  {
    id: 11,
    obraNombre: "Tanque 5.000 m³",
    imgUrl: "../images/obras/tanque-5000m/tanque-5000m-01.jpg",
    linkUrl: "/obras/tanque-5000m/",
  },
  {
    id: 12,
    obraNombre: "Tanque 10.000 m³",
    imgUrl: "../images//obras/tanque-10000m/tanque-1000m-01.jpg",
    linkUrl: "/obras/tanque-10000m/",
  },


  // Line 5
  {
    id: 13,
    obraNombre: "Tanque 20.000 m³",
    imgUrl: "../images/obras/tanque-20000m/tanque-2000m-01.jpg",
    linkUrl: "/obras/tanque-20000m/",
  },
  {
    id: 14,
    obraNombre: "Tanque 50.000 m³",
    imgUrl: "../images/obras/tanque-50000m/tanque-50000m-00.jpg",
    linkUrl: "/obras/tanque-50000m/",
  },
  
];
