import React, {useState, useEffect} from 'react';
import DivisionThumb from './DivisionThumb/DivisionThumb'
import DivisionContainerInternas from '../DivisionContainer/DivisionContainerInternas'
import Loading from '../Commons/Loading/Loading';
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom';


import './Divisiones.scss';

const AislacionesTermicas = () => {

    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (paramUrl, paramDesc) => {
        
        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)
        
        console.log(paramUrl, ', ', paramDesc);
    };


    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return    <section className='container-fluid p-0 pb-0 display-block'>

                <Modal
                    show={show}
                    size='lg'
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    >
                    <Modal.Header closeButton>
                        <Modal.Title>Cintas calefactoras.</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
                    </Modal.Body>

                    <Modal.Footer>
                        <small >{txtDes}</small>
                    </Modal.Footer>
                </Modal>

                <div className='divisionItem__container animated fast fadeIn'>
                    <img src="../images/headers/headers-aislaciones-termicas.jpg" 
                        alt="Reparacion de Tanques." 
                        loading='lazy'
                        className='divisionItem__container--img'    
                    />
                    <div className='divisionItem__container--textos' >
                        <h3 className='divisionItem__container--header'>DIVISION</h3>
                        <h1 className='divisionItem__container--title'>AISLACIONES TERMICAS</h1>
                        
                    </div>
                </div>

                <div className='container-fluid divisionItem__detail'>
                    <div className='container animated fast fadeIn'>

                    <div className='row justify-content-center proyecto__containers'>
                            <DivisionThumb 
                                imgUrl ={'./images/divisiones/aislaciones-termicas/cinta-calefactora/cinta-calefactora-00.jpg'}
                                titulo ={'Cinta calefactora'}
                                linkUrl = {'/aislaciones-termicas/cinta-calefactora/'}
                                altImg={'Cinta calefactora'}
                            />
                            <DivisionThumb 
                                imgUrl ={'./images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-00.jpg'}
                                titulo ={'Recubrimiento chapa'}
                                linkUrl = {'/aislaciones-termicas/recubrimiento-chapa/'}
                                altImg={'Recubrimiento de chapa'}
                            />
                            
                    </div>

                        {/* <Link to={'/'}>
                        <small> 
                            <i className="fas fa-angle-double-left" style={{'paddingBottom':'15px', 'paddingRight': '10px'}} ></i> 
                            Volver a Inicio</small>
                        </Link> */}

                        {/* <div className='row'>
                            <div className='col-md-6'> 
                                
                                <img 
                                    src="../images/divisiones/aislaciones-termicas/cinta-calefactora-00.jpg" 
                                    alt="Tanque de 50.000m3. con la obra terminada."
                                    className='d-block w-100 divisionItem__detail--header'
                                    loading='lazy'
                                /> 

                            </div>

                            <div className='col-md-6 divisionItem__detail--block'>
                                <h1 className='divisionItem__detail--title' >Cintas Calefactoras y Recubrimiento de Chapa</h1>
                                <p  className='divisionItem__detail--text' > 
                                    Desarrollamos aislaciones térmicas con el objetivo de conservar la temperatura y/o protección del personal del calor que exponen los activos como cañerías y equipos. Para ciertos casos de mantener los niveles de temperatura, colocamos cinta calefactora siendo un sistema de traceado eléctrico que puede considerarse distancias en kilómetros o pocos metros. Para cada sistema se evalúa por cálculo la distribución de cada elemento para alcanzar el gradiente de temperatura estimado, seguidamente se monta lana mineral y chapa de aluminio. <br /><br />
                                    En el caso de altas temperaturas desarrollamos el sistema de aislación para el calor, cociendo colchones de lana mineral y disponiendo de chapas de aluminio con sus respectivas terminaciones en función de la geometría del elemento. <br /><br />
                                    Ambos sistemas son ejecutados con materiales certificados y su control de calidad correspondiente. 
                                <br/>
                                </p>
                            </div>

                        </div> */}

                        {/* <div className='gallery__cont'>

                            <div className='gallery__cont--column'>
                            
                                <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/cinta-calefactora/cinta-calefactora-02.jpg', 'Obra realizadas.') }  src='../images/divisiones/aislaciones-termicas/cinta-calefactora/cinta-calefactora-02.jpg' alt='Cintas Calefactoras aplicadas' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/cinta-calefactora/cinta-calefactora-01.jpg', 'Obra realizadas.') } src='../images/divisiones/aislaciones-termicas/cinta-calefactora/cinta-calefactora-03.jpg' alt='Cintas Calefactoras aplicadas' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-02.jpg', 'Obra realizadas.') }  src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-02.jpg' alt='Cintas Calefactoras aplicadas' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-01.jpg', 'Obra realizadas.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-03.jpg' alt='Cintas Calefactoras aplicadas' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-04.jpg', 'Obra realizadas.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-04.jpg' alt='Cintas Calefactoras aplicadas' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-18.jpg', 'Obra realizadas.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-18.jpg' alt='Cintas Calefactoras aplicadas' loading='lazy' />
                            
                            </div>

                            <div className='gallery__cont--column'>
                                <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-13.jpg', 'Obra realizadas.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-13.jpg' alt='Cintas Calefactoras aplicadas' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-05.jpg', 'Obra realizadas.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-05.jpg' alt='Cintas Calefactoras aplicadas' loading='lazy' /> <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-05.jpg', 'Obra realizadas.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-05.jpg' alt='Cintas Calefactoras aplicadas' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-06.jpg', 'Obra realizadas.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-06.jpg' alt='Cintas Calefactoras aplicadas' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-14.jpg', 'Obra realizadas.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-14.jpg' alt='Cintas Calefactoras aplicadas' loading='lazy' />
                                 
                            </div>

                            <div className='gallery__cont--column'>
                                <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/cinta-calefactora/cinta-calefactora-05.jpg', 'Obra realizadas.') } src='../images/divisiones/aislaciones-termicas/cinta-calefactora/cinta-calefactora-05.jpg' alt='Cintas Calefactoras aplicadas' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-17.jpg', 'Obra realizadas.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-17.jpg' alt='Cintas Calefactoras aplicadas' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-07.jpg', 'Obra realizadas.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-07.jpg' alt='Cintas Calefactoras aplicadas' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-08.jpg', 'Obra realizadas.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-08.jpg' alt='Cintas Calefactoras aplicadas' loading='lazy' />
                            
                            </div>

                            <div className='gallery__cont--column'>
                                <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/cinta-calefactora/cinta-calefactora-04.jpg', 'Obra realizadas.') } src='../images/divisiones/aislaciones-termicas/cinta-calefactora/cinta-calefactora-04.jpg' alt='Cintas Calefactoras aplicadas' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-10.jpg', 'Obra realizadas.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-10.jpg' alt='Cintas Calefactoras aplicadas' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-11.jpg', 'Obra realizadas.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-11.jpg' alt='Cintas Calefactoras aplicadas' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-16.jpg', 'Obra realizadas.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-16.jpg' alt='Cintas Calefactoras aplicadas' loading='lazy' />
                            </div>

                            <div className='gallery__cont--column'>
                                <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/cinta-calefactora/cinta-calefactora-06.jpg', 'Obra realizadas.') } src='../images/divisiones/aislaciones-termicas/cinta-calefactora/cinta-calefactora-06.jpg' alt='Cintas Calefactoras aplicadas' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-09.jpg', 'Obra realizadas.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-09.jpg' alt='Cintas Calefactoras aplicadas' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-15.jpg', 'Obra realizadas.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-15.jpg' alt='Cintas Calefactoras aplicadas' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-12.jpg', 'Obra realizadas.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-12.jpg' alt='Cintas Calefactoras aplicadas' loading='lazy' />
                            </div> 
                        
                        </div> */}
                    </div>
                </div>
                <div className='container container__divisiones'>
                    
                    <div className='text-center division__bajada--down' >
                        <i className="fas fa-angle-down fa-3x"></i>
                    </div>
                    
                    {/* <h3 className='division__bajada--tit'>
                        Tareas Destacadas
                    </h3> */}
                
                    <h3 className='division__bajada--tit text-center'>
                        Otras divisiones
                    </h3>
                    <div>
                        <DivisionContainerInternas divSelect={'aislaciones-termicas'} />
                    </div>

                </div>

                <FooterFormulario className='mt-5' />
            </section>

};

export default AislacionesTermicas;
