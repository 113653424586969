import React, { useState, useEffect } from 'react'
import Loading from '../Commons/Loading/Loading';
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import '../Divisiones/DivisionesDetalles.scss'
import DivisionBanner from '../DivisionContainer/DivisionBanner/DivisionBanner';
import { Modal } from 'react-bootstrap'
import ObraThumb from '../Commons/ObraThumb/ObraThumb';
import ObrasRealizadas from '../Commons/ObrasRealizadas/ObrasRealizadas';


const Tanque50000 = () => {


    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = (paramUrl, paramDesc) => {

        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)

        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    })

    if (loading) { return <Loading /> }

    return (

        <section className='container-fluid p-0 containerBlock' >


            <Modal
                show={show}
                size='lg'
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Tanque 71 de 50.000 m³.</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
                </Modal.Body>

                <Modal.Footer>
                    <small >{txtDes}</small>
                </Modal.Footer>

            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-obras-civiles.jpg"
                    alt="Obras, andamios."
                    loading='lazy'
                    className='divisionItem__container--img'
                />

                <div className='divisionItem__container--textos divisionItem__container--textos-obras' >
                    <h3 className='divisionItem__container--header'>OBRAS</h3>
                    <h1 className='divisionItem__container--title'>Tanque 50.000 m³</h1>
                </div>

            </div>

            <div className='container-fluid obrasItem__detail'>
                <div className='container animated fast fadeIn'>
                    <Link to={'/obras/'}>
                        <small>
                            <i className="fas fa-angle-double-left" style={{ 'paddingBottom': '15px', 'paddingRight': '10px' }} ></i>
                            Volver a Obras
                        </small>
                    </Link>

                    <div className='row'>
                        <div className='col-md-6'>
                            <img
                                src="../images/obras/tanque-50000m/tanque-50000m-01.jpg"
                                alt="Tanque 71 - TCC de 50000 m3"
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            />
                        </div>
                        <div className='col-md-6 divisionItem__detail--block'>
                            <h1 className='divisionItem__detail--title' >Tanque 71 </h1>
                            <h3 className='divisionItem__detail--subtitle' >
                                <i className="fas fa-map-marker-alt" style={{ 'paddingBottom': '15px', 'paddingRight': '10px' }}></i>
                                TERMAP Caleta Córdova - Chubut</h3>

                            <p className='divisionItem__detail--text pt-2' >
                                Reparación parcial de tanque de 50.000 m² identificado como “TK71”.<br /> <br />
                                Con una altura de 18.175 mm y un diámetro de 60.640 mm. Compuesto por techo flotante y 115 columnas. La construcción del tanque es soldado bajo normas API.

                            </p>

                        </div>
                    </div>

                    <div className='row obras__box'>
                        <div className='col-6 col-sm-3 col-md-6 col-lg-3 obras__card'>
                            <ObraThumb
                                ObraNum={'4.4'}
                                ObraVal={''}
                                ObraItem={'Toneladas'}
                                ObraDet={'Piso de chapa anular'}
                            />
                        </div>
                        <div className='col-6 col-sm-3 col-md-6 col-lg-3 obras__card'>
                            <ObraThumb
                                ObraNum={'40'}
                                ObraVal={' '}
                                ObraItem={'Toneladas'}
                                ObraDet={'Piso de chapa central'}
                            />
                        </div>
                        <div className='col-6 col-sm-3 col-md-6 col-lg-3 obras__card'>
                            <ObraThumb
                                ObraNum={'50.000'}
                                ObraVal={''}
                                ObraItem={'m3'}
                                ObraDet={'Pintura total'}
                            />
                        </div>
                        <div className='col-6 col-sm-3 col-md-6 col-lg-3 obras__card'>
                            <ObraThumb
                                ObraNum={'1.3'}
                                ObraVal={''}
                                ObraItem={'Toneladas'}
                                ObraDet={'Pivot master nuevo'}
                            />
                        </div>

                    </div>

                    <div className='gallery__cont'>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/tanque-50000m/tanque-50000m-02.jpg', 'Obra tanque de 50.000 m3')} src='../images/obras/tanque-50000m/tanque-50000m-02.jpg' alt='Tanque de 50000m3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/tanque-50000m/tanque-50000m-03.jpg', 'Obra tanque de 50.000 m3')} src='../images/obras/tanque-50000m/tanque-50000m-03.jpg' alt='Tanque de 50000m3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/tanque-50000m/tanque-50000m-05.jpg', 'Obra tanque de 50.000 m3')} src='../images/obras/tanque-50000m/tanque-50000m-05.jpg' alt='Tanque de 50000m3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/tanque-50000m/tanque-50000m-09.jpg', 'Obra tanque de 50.000 m3')} src='../images/obras/tanque-50000m/tanque-50000m-09.jpg' alt='Tanque de 50000m3' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/tanque-50000m/tanque-50000m-06.jpg', 'Obra tanque de 50.000 m3')} src='../images/obras/tanque-50000m/tanque-50000m-06.jpg' alt='Tanque de 50000m3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/tanque-50000m/tanque-50000m-04.jpg', 'Obra tanque de 50.000 m3')} src='../images/obras/tanque-50000m/tanque-50000m-04.jpg' alt='Tanque de 50000m3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/tanque-50000m/tanque-50000m-08.jpg', 'Obra tanque de 50.000 m3')} src='../images/obras/tanque-50000m/tanque-50000m-08.jpg' alt='Tanque de 50000m3' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/tanque-50000m/tanque-50000m-07.jpg', 'Obra tanque de 50.000 m3')} src='../images/obras/tanque-50000m/tanque-50000m-07.jpg' alt='Tanque de 50000m3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/tanque-50000m/tanque-50000m-16.jpg', 'Obra tanque de 50.000 m3')} src='../images/obras/tanque-50000m/tanque-50000m-13.jpg' alt='Tanque de 50000m3' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/tanque-50000m/tanque-50000m-10.jpg', 'Obra tanque de 50.000 m3')} src='../images/obras/tanque-50000m/tanque-50000m-10.jpg' alt='Tanque de 50000m3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/tanque-50000m/tanque-50000m-11.jpg', 'Obra tanque de 50.000 m3')} src='../images/obras/tanque-50000m/tanque-50000m-11.jpg' alt='Tanque de 50000m3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/tanque-50000m/tanque-50000m-12.jpg', 'Obra tanque de 50.000 m3')} src='../images/obras/tanque-50000m/tanque-50000m-12.jpg' alt='Tanque de 50000m3' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/tanque-50000m/tanque-50000m-13.jpg', 'Obra tanque de 50.000 m3')} src='../images/obras/tanque-50000m/tanque-50000m-14.jpg' alt='Tanque de 50000m3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/tanque-50000m/tanque-50000m-14.jpg', 'Obra tanque de 50.000 m3')} src='../images/obras/tanque-50000m/tanque-50000m-15.jpg' alt='Tanque de 50000m3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/tanque-50000m/tanque-50000m-15.jpg', 'Obra tanque de 50.000 m3')} src='../images/obras/tanque-50000m/tanque-50000m-16.jpg' alt='Tanque de 50000m3' loading='lazy' />
                        </div>

                    </div>
                </div>
            </div>
            <div className='otrasObras__container'>
                <ObrasRealizadas cantObras={5} excluir={14} />
            </div>

            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default Tanque50000