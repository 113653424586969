import React from 'react'
import './BannerAniversario.scss'

const BannerAniversario = () => {
    return (

        <div className='container-fluid p-0 pt-2 bannerAnivesaryContainer'>
            <div className='row justify-content-md-center bannerAnivesary__block'>

                <div className='col-md-2 bannerAnivesary__text pl-5'>
                    <span className='bannerAnivesary__text--white'>+30 años</span>
                    <span className='bannerAnivesary__text--grey'>De experiencia</span>
                </div>
                
                <div className='col-1 bannerAniversary__col'>
                    <img src='../images/home/line_h.jpg' className='d-sm-none .d-md-block bannerAnivesary__text--line'  alt='linea de separacion'/>
                    <img src='../images/home/line.jpg' className='bannerAnivesary__text--line' alt='linea de separacion'/>
                </div>

                <div className='col-md-3 bannerAnivesary__text'>
                    <span className='bannerAnivesary__text--white'>+25 Años</span>
                    <span className='bannerAnivesary__text--grey'>En Yacimiento San Jorge</span>
                </div>
                
                <div className='col-1 bannerAniversary__col'>
                    <img src='../images/home/line_h.jpg' className='d-sm-none .d-md-block bannerAnivesary__text--line'  alt='linea de separacion'/>
                    <img src='../images/home/line.jpg' className='bannerAnivesary__text--line'  alt='linea de separacion'/>
                </div>
                
                <div className='col-md-2 bannerAnivesary__text'>
                    <span className='bannerAnivesary__text--white'>+35 Tanques</span>
                    <span className='bannerAnivesary__text--grey'>De <i className="fas fa-angle-right"></i> 10.000 m3</span>
                </div>
            
            </div>
            <p className='text__25'> +30 </p>   
        </div>
    )
}

export default BannerAniversario
