import React, { useEffect, useState } from "react";
import Loading from "../Commons/Loading/Loading";
import DivisionThumb from "../Divisiones/DivisionThumb/DivisionThumb";
import FooterFormulario from "../Commons/FooterFormulario/FooterFormulario";

import "../Divisiones/Divisiones.scss";

const Obras = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <section className="container-fluid p-0 pb-0 display-block">
      <div className="division__container animated fast fadeIn">
        <img
          src="../images/headers/headers-obras-civiles.jpg"
          alt="Radiadores industriales."
          loading="lazy"
          className="division__container--img"
        />

        <div className="division__container--textos">
          {/* <h3 className='division__container--header'></h3> */}
          <h1 className="division__container--title">Obras</h1>
          <p className="division__container--text">
            Nuestra amplia capacidad y equipamientos nos da la oportunidad de
            resolver distintos tipos de trabajos. Entre ellos proyectos civiles,
            metalúrgicos, montaje y desmontaje, etc.
            <br />
            <br />
            Contamos con un gran equipo logístico que permite a nuestra organización
            conectar con las distintas divisiones. Nos enfocamos en la seguridad,
            calidad y medio ambiente de cada obra.
          </p>
        </div>
      </div>

      <div className="container container__divisiones">
        <div className="text-center division__bajada--down">
          <i className="fas fa-angle-down fa-3x"></i>
        </div>

        <div className="row justify-content-center proyecto__containers">
          {/* Line 1 */}
          <DivisionThumb
            imgUrl={"./images/obras/tanque-tk-tp35/tanque-tk-tp35-00.jpg"}
            division={"Construcción y reparación de tanques"}
            titulo={"Reparación Tanque"}
            titulo2={"235 m³"}
            linkUrl={"/obras/reparacion-tanque-235/"}
            altImg={"Reparación Tanque 235 m³"}
            style={{ marginBottom: "20px" }}
          />
          <DivisionThumb
            imgUrl={"./images/obras/tanque-tk-tp20/tanque-tk-tp20-01.jpg"}
            division={"Construcción y reparación de tanques"}
            titulo={"Reparación Tanque"}
            titulo2={"160 m³"}
            linkUrl={"/obras/reparacion-tanque-160/"}
            altImg={"Reparación Tanque 160 m³"}
            style={{ marginBottom: "20px" }}
          />
          <DivisionThumb
            imgUrl={"./images/obras/tanque-320m3/tanque-320m3-00.jpg"}
            division={"Construcción y reparación de tanques"}
            titulo={"Construcción Tanque"}
            titulo2={"320 m³"}
            linkUrl={"/obras/construccion-tanque-320/"}
            altImg={"Construcción Tanque 320m3"}
            style={{ marginBottom: "20px" }}
          />

          <DivisionThumb
            imgUrl={"./images/obras/tanque-tk-471b/tanque-tk-471b-18.jpg"}
            division={"Construcción y reparación de tanques"}
            titulo={"Construcción de Tanque Skimmer 2548 m³"}
            linkUrl={"/obras/construccion-tanque-471b/"}
            altImg={"Construcción de Tanque Skimmer 2548 m³"}
            style={{ marginBottom: "20px" }}
          />


          {/* Line 2 */}
          <DivisionThumb
            imgUrl={"./images/obras/aislacion-lh3/aislacion-lh3-00.jpg"}
            division={"Aislaciones termicas"}
            titulo={"Aislacion de LH03"}
            linkUrl={"/obras/aislacion-de-lh03"}
            altImg={"Aislacion de LH03"}
            style={{ marginBottom: "20px" }}
          />
          <DivisionThumb
            imgUrl={"./images/obras/aislacion-piu/aislacion-piu-01.jpg"}
            division={"Aislaciones termicas"}
            titulo={"Aislacion en piu"}
            linkUrl={"/obras/aislacion-en-piu"}
            altImg={"Aislacion en piu"}
            style={{ marginBottom: "20px" }}
          />
          <DivisionThumb
            imgUrl={"./images/obras/spool/spool-00.jpg"}
            division={"Construcción y reparación de tanques"}
            titulo={"Spool"}
            linkUrl={"/obras/spool"}
            altImg={"Spool"}
            style={{ marginBottom: "20px" }}
          />


          {/* Line 3 */}
          <DivisionThumb
            imgUrl={"./images/obras/radiador-guascor/radiador-guascor.jpg"}
            division={"Radiadores"}
            titulo={"Radiador Guascor FG240"}
            linkUrl={"/obras/radiador-guascor-fg-24"}
            altImg={"Spool"}
            style={{ marginBottom: "20px" }}
          />
          <DivisionThumb
            imgUrl={"./images/obras/capsa-capex/capsa-capex-00.jpg"}
            division={"Obras Civiles"}
            titulo={"Mantenimiento RCI"}
            linkUrl={"/obras/capsa-capex"}
            altImg={"Mantenimiento RCI"}
            style={{ marginBottom: "20px" }}
          />
          <DivisionThumb
            imgUrl={"./images/obras/andamios-20m/andamio.jpg"}
            division={"Armado de Andamios"}
            titulo={"Andamio > 20 metros de altura"}
            linkUrl={"/obras/andamio-20m"}
            altImg={"Spool"}
            style={{ marginBottom: "20px" }}
          />


          {/* Line 4 */}
          <DivisionThumb
            imgUrl={"./images/obras/obra-civil-antares/antares-02.jpg"}
            division={"Obras Civiles"}
            titulo={"Obra Civil Antares"}
            linkUrl={"/obras/obra-civil-antares"}
            altImg={"Obra Civil Antares"}
          />
          <DivisionThumb
            imgUrl={"./images/obras/tanque-5000m/tanque-5000m-01.jpg"}
            division={"Construcción y reparación de tanques"}
            titulo={"Tanque 5.000 m³"}
            linkUrl={"/obras/tanque-5000m/"}
            altImg={"Tanque 5.000 m³s"}
          />
          <DivisionThumb
            imgUrl={"./images/obras/tanque-10000m/tanque-1000m-01.jpg"}
            division={"Construcción y reparación de tanques"}
            titulo={"Tanque 10.000 m³"}
            linkUrl={"/obras/tanque-10000m/"}
            altImg={"Tanque 10.000 m³"}
          />


          {/* Line 5 */}
          <DivisionThumb
            imgUrl={"./images/obras/tanque-20000m/tanque-2000m-01.jpg"}
            division={"Construcción y reparación de tanques"}
            titulo={"Tanque 20.000 m³"}
            linkUrl={"/obras/tanque-20000m/"}
            altImg={"Tanque 20.000 m³"}
          />
          <DivisionThumb
            imgUrl={"./images/obras/tanque-50000m/tanque-50000m-00.jpg"}
            division={"Construcción y reparación de tanques"}
            titulo={"Tanque 50.000 m³"}
            linkUrl={"/obras/tanque-50000m/"}
            altImg={"Tanque 50.000 m³"}
          />

        </div>
      </div>
      <FooterFormulario className="mt-5" />
    </section>
  );
};

export default Obras;
