import React from "react";
import { Link } from "react-router-dom";

import "./ObrasRealizadas.scss";
import ObrasRealizadaThumb from "./ObrasRealizadaThumb";
import { OBRAS } from "../../constant";

const INIT_PAGE = "HOME";

const ObrasRealizadas = ({ pageName, cantObras, excluir }) => {

  // FUNCION PARA MEZCLAR EL ARRAY
  function shuffleObras(OBRAS) {
    const obrasFiltradas = excluir ? OBRAS.filter((_, index) => index !== excluir) : OBRAS
    const shuffled = [...obrasFiltradas];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  }


  function renderObrasRealizadas(obrasAleatorias, lenghtObras) {
    const obrasList = obrasAleatorias.slice(0, lenghtObras);
    return (
      <>
        {obrasList.map((obra, index) =>
          <ObrasRealizadaThumb
            key={index}
            obraNombre={obra.obraNombre}
            imgUrl={obra.imgUrl}
            linkUrl={obra.linkUrl} />
        )}
      </>
    )
  }

  const obrasAleatorias = shuffleObras(OBRAS).slice(0, cantObras);

  return (
    pageName === INIT_PAGE
      ?
      <section className="container-fluid obras__container">
        <div className="container">
          <div class="row d-flex justify-content-center">
            <div className="col-md-2 obras__titles--box">
              <h3 class="obras__titles obras__titles--box text-left">
                Proyectos realizados
              </h3>
              <p class="quienesSomos__container--textBlack">
                Explora nuestros proyectos de las diferentes divisiones para mas
                detalle ingresa a nuestra seccion de obra.
                <br />
                <br />
                <Link to={"/obras/"} className="button__item">
                  <i class="fas fa-arrow-right" />
                  &nbsp;&nbsp;Ver Proyectos{" "}
                </Link>
              </p>
            </div>
            {renderObrasRealizadas(obrasAleatorias, cantObras)}
            <div>
            </div>
          </div>
        </div>
      </section>
      : <section className="container-fluid obras__container">
        <div className="container">
          <div class="row d-flex justify-content-center">
            <h3 class="obras__titles obras__titles--box text-center pb-3">
              Otras Obras<br /><br />
            </h3>
          </div>
          <div class="row d-flex justify-content-center">
            {renderObrasRealizadas(obrasAleatorias, cantObras)}
          </div>
        </div>
      </section>
  );
};

export default ObrasRealizadas;
