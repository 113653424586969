import React, { useState, useEffect } from 'react'
import Loading from '../Commons/Loading/Loading';
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import '../Divisiones/DivisionesDetalles.scss'
import DivisionBanner from '../DivisionContainer/DivisionBanner/DivisionBanner';
import { Modal } from 'react-bootstrap'
import ObrasRealizadas from '../Commons/ObrasRealizadas/ObrasRealizadas';


const ObraCivilAntares = () => {


    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = (paramUrl, paramDesc) => {

        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)

        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    })

    if (loading) { return <Loading /> }

    return (

        <section className='container-fluid p-0 containerBlock' >


            <Modal
                show={show}
                size='lg'
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Andamio &#62;20 metros de altura.</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
                </Modal.Body>

                <Modal.Footer>
                    <small >{txtDes}</small>
                </Modal.Footer>

            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-obras-civiles.jpg"
                    alt="Obras, andamios."
                    loading='lazy'
                    className='divisionItem__container--img'
                />

                <div className='divisionItem__container--textos divisionItem__container--textos-obras' >
                    <h3 className='divisionItem__container--header'>OBRAS</h3>
                    <h1 className='divisionItem__container--title'>Obra Civil Antares</h1>
                </div>

            </div>

            <div className='container-fluid divisionItem__detail'>
                <div className='container animated fast fadeIn'>
                    <Link to={'/obras/'}>
                        <small>
                            <i className="fas fa-angle-double-left" style={{ 'paddingBottom': '15px', 'paddingRight': '10px' }} ></i>
                            Volver a Obras
                        </small>
                    </Link>

                    <div className='row'>

                        <div className='col-md-6'>
                            <img
                                src="../images/obras/obra-civil-antares/antares.jpg"
                                alt="Andamio >20 metros de altura."
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            />
                        </div>

                        <div className='col-md-6 divisionItem__detail--block'>
                            <h1 className='divisionItem__detail--title' >Antares (platea de alto transito)</h1>
                            <h3 className='divisionItem__detail--subtitle' >
                                <i className="fas fa-map-marker-alt" style={{ 'paddingBottom': '15px', 'paddingRight': '10px' }}></i>
                                TERMAP Caleta Córdova - Chubut
                            </h3>
                            <p className='divisionItem__detail--text' >
                                <ul>
                                    <li>Platea 30 Mpa:  453 m³ hormigón </li>
                                    <li>Armadura doble: media tonelada</li>
                                    <li>Juntas de dilatación + sellador </li>
                                    <li>Terminación fratachada lisa</li>
                                </ul>
                            </p>

                        </div>

                    </div>

                    <div className='gallery__cont'>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/obra-civil-antares/antares-01.jpg', 'Obra realizada en Antares.')} src='../images/obras/obra-civil-antares/antares-01.jpg' alt='Obra civil en Antares.' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/obra-civil-antares/antares-02.jpg', 'Obra realizada en Antares.')} src='../images/obras/obra-civil-antares/antares-02.jpg' alt='Obra civil en Antares.' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/obra-civil-antares/antares-01.jpg', 'Obra realizada en Antares.')} src='../images/obras/obra-civil-antares/antares-03.jpg' alt='Obra civil en Antares.' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/obra-civil-antares/antares-04.jpg', 'Obra realizada en Antares.')} src='../images/obras/obra-civil-antares/antares-04.jpg' alt='Obra civil en Antares.' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/obra-civil-antares/antares-05.jpg', 'Obra realizada en Antares.')} src='../images/obras/obra-civil-antares/antares-05.jpg' alt='Obra civil en Antares.' loading='lazy' />
                        </div>

                    </div>
                </div>
            </div>
            <div>
                <ObrasRealizadas cantObras={5} excluir={9} />
            </div>
            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default ObraCivilAntares