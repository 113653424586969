import React, {useState, useEffect} from 'react'
import Loading from '../Commons/Loading/Loading';
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import '../Divisiones/DivisionesDetalles.scss'
import DivisionBanner from '../DivisionContainer/DivisionBanner/DivisionBanner';
import { Modal } from 'react-bootstrap'
import ObraThumb from '../Commons/ObraThumb/ObraThumb';


const LagunaDeLosPadres = () => {


    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (paramUrl, paramDesc) => {
        
        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)
        
        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return (

        <section className='container-fluid p-0 containerBlock' > 


            <Modal
              show={show}
              size='lg'
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Red Metropolitana de Fibra Óptica</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
              </Modal.Body>

              <Modal.Footer>
                <small >{txtDes}</small>
              </Modal.Footer>

            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-telecomunicaciones.jpg" 
                    alt="Obras, andamios." 
                    loading='lazy'
                    className='divisionItem__container--img'    
                />

                <div className='divisionItem__container--textos divisionItem__container--textos-obras' >
                    <h3 className='divisionItem__container--header'>OBRAS</h3>
                    <h1 className='divisionItem__container--title'>TELECOMUNICACIONES</h1>
                </div>

            </div>

            <div className='container-fluid obrasItem__detail'>
                <div className='container animated fast fadeIn'>
                    <Link to={'/obras/'}>
                        <small> 
                        <i className="fas fa-angle-double-left" style={{'paddingBottom':'15px', 'paddingRight': '10px'}} ></i> 
                        Volver a Obras
                        </small>
                    </Link>

                    <div className='row'>

                        <div className='col-md-5'>     
                            <img 
                                src="../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-01.jpg" 
                                alt="Trabajo en la red Metropolitana de Fibra Optica de Agua."
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            /> 
                        </div>

                        <div className='col-md-7 divisionItem__detail--block'>
                            <h1 className='divisionItem__detail--title' >Red de FTTH.</h1>
                            
                            <p  className='divisionItem__detail--text pt-2' > 

                            Red de FTTH realizada para la Cooperadora de Laguna de los Padres.<br/>
                            Se instalaron 189 Nps y 69 Empalmes.<br/>
                            Se tendieron 28 km de FO y 20 Km de Drop.<br/>

                            <br />
                            </p>

                        </div>

                    </div>
                    
                    <div className='row obras__box'>
                        <div className='col-6 col-sm-3 col-md-6 col-lg-3 obras__card'>
                            <ObraThumb  
                                ObraNum  = {'189'}
                                ObraVal  = {''}
                                ObraItem = {'Nps'}
                                ObraDet  = {'Instaladas'}
                            />
                        </div>
                        <div className='col-6 col-sm-3 col-md-6 col-lg-3 obras__card'>
                            <ObraThumb  
                                ObraNum  = {'69'}
                                ObraVal ={''}
                                ObraItem = {'Empalmes'}
                                ObraDet  = {'Instalados'}
                            />
                        </div>
                        <div className='col-6 col-sm-3 col-md-6 col-lg-3 obras__card'>
                            <ObraThumb  
                                ObraNum  = {'28'}
                                ObraVal ={'KM'}
                                ObraItem = {''}
                                ObraDet  = {'Fibra Óptica'}
                            />
                        </div>
                        <div className='col-6 col-sm-3 col-md-6 col-lg-3 obras__card'>
                            <ObraThumb  
                                ObraNum  = {'20'}
                                ObraVal ={'km'}
                                ObraItem = {'Drop'}
                                ObraDet  = {''}
                            />
                        </div>
                    </div>


                    <div className='gallery__cont'>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-01.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-01.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy'/>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-02.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-02.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-03.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-03.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-04.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-04.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-05.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-05.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-06.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-06.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                           
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-07.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-07.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-08.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-08.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-09.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-09.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-10.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-10.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-11.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-11.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-12.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-12.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-13.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-13.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy'/>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-14.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-14.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy'/>
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-16.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-16.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy'/>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-17.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-17.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-18.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-18.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-15.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-15.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy'/>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-19.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-19.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-20.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-20.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                          
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-21.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-21.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-22.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-22.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-23.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-23.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-24.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-24.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-25.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-25.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-26.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-26.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' /> 
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-27.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-27.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-28.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-28.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-29.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-29.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-30.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-30.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-31.jpg', 'Trabajo en la red Metropolitana de Fibra Optica') } src='../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres/obra-sierra-de-los-padres-31.jpg' alt='Trabajo en la red Metropolitana de Fibra Optica' loading='lazy' /> 
                        </div>

                    </div>
                </div>
            </div>
            <div className='otrasObras__container'>

                <h3 className='divisionItem__bajada--tit text-center'>
                   Otras Obras
                </h3>

                <div className='row divisionItem__proyectosDestacados p-0'> 
                    <DivisionBanner 
                        division = {'Enlaces en Rada Tilly'}
                        imgUrl = {'../images/obras/telecomunicaciones/enlaces-en-rada-tilly.jpg'}
                        linkUrl = {'/obras/enlaces-en-rada-tilly/'}
                    /> 
                    <DivisionBanner 
                        division = {'FO para Largos Vanos'}
                        imgUrl = {'../images/obras/telecomunicaciones/fo-para-largos-vanos.jpg'}
                        linkUrl = {'/obras/fo-para-largos-vanos/'}
                    /> 
                    <DivisionBanner 
                        division = {'Red DWDM Gran Ancho de Banda FO Zero Pico de Agua.'}
                        imgUrl = {'../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua.jpg'}
                        linkUrl = {'/obras/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/'}
                    />
                    <DivisionBanner 
                        division = {'Enlaces Urbanos punto a punto en Corrientes'}
                        imgUrl = {'../images/obras/telecomunicaciones/enlaces-urbanos-punto-a-punto.jpg'}
                        linkUrl = {'/obras/enlaces-urbanos-punto-a-punto/'}
                    /> 
                </div>

            </div>

            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default LagunaDeLosPadres