import React from 'react'
import ButtonDark from '../../Commons/Button/ButtonDark'
import './DivisionThumb.scss'
import { Link } from 'react-router-dom'

const DivisionBanner = ( props ) => {

        
    return (
        <div className='col-xs-4 col-md-6 col-lg-4 pb-3 proyectoContainer'>
            <div className='proyectoContainer__thumb'>
                <Link to={`${props.linkUrl}`}>
                    <div className='proyecto__item'> 
                        <div className='proyecto__item--marco'></div>
                        <img src={props.imgUrl} className="w-100 " alt={props.altImg} loading="lazy" />
                    </div>
                </Link>
                
                <p className='proyecto__tit'>
                    <span className='proyecto__division'>{ props.division }</span>
                    { props.titulo }<br />
                    { props.titulo2 }
                </p>
                <div className='text-center pb-4' >
                    <Link to={`${props.linkUrl}`}>
                        <ButtonDark toDo='+ Detalles' type='button'/>                 
                    </Link>
                </div>
            </div>
        </div>   
    ) 

}

export default DivisionBanner
