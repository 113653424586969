import React, {useState, useEffect} from 'react'
import Loading from '../../Commons/Loading/Loading';
import FooterFormulario from '../../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import DivisionBanner from '../../DivisionContainer/DivisionBanner/DivisionBanner';
import { Modal } from 'react-bootstrap'

import '../../../Styles/main.scss'
import '../DivisionesDetalles.scss'
import ButtonDark from '../../Commons/Button/ButtonDark';

const AutomatizacionDomotica = () => {

    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (paramUrl, paramDesc) => {
        
        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)
        
        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return (

        <section className='container-fluid p-0 containerBlock' > 


            <Modal
              show={show}
              size='lg'
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Video Vigilancia</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
              </Modal.Body>

              <Modal.Footer>
                {/* <small >{txtDes}</small> */}
              </Modal.Footer>
            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-networking.jpg" 
                    alt="Video Vigilancia." 
                    loading='lazy'
                    className='divisionItem__container--img divisionItem__container--img--telecomunicaciones'    
                />

                <div className='divisionItem__container--textos divisionItem__container--textos-2-lineas divisionItem__container--textos-telecomunicaciones' >
                    <h3 className='divisionItem__container--header'>DIVISION</h3>
                    <h1 className='divisionItem__container--title divisionItem__container--title--telecomunicaciones'>TELECOMUNICACIONES</h1>
                </div>

            </div>

            <div className='container-fluid divisionItem__detail'>

                <div className='container animated fast fadeIn'>
                    <Link to={'/telecomunicaciones/'}>
                    <small> 
                        <i className="fas fa-angle-double-left" style={{'paddingBottom':'15px', 'paddingRight': '10px'}} ></i> 
                        Volver a Division</small>
                    </Link>

                    <div className='row'>

                        <div className='col-md-4 mb-5 pt-4'> 
                            <img 
                                src="../images/divisiones/telecomunicaciones/seguridad-electronica-y-domotica/automatizacion-y-domotica/imagen-0.jpg" 
                                alt="Enlaces-de-largos vanos-y-dwdm"
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            /> 
                        </div>

                        <div className='col-md-8 divisionItem__detail--block'>
                            <h1 className='divisionItem__detail--title' >Automatización y Domóticaia</h1>
                            <p  className='divisionItem__detail--text' > 
                            Siendo posible el uso de sistemas como reconocimiento facial, lectura de patentes, 
                            seguimiento de personas y objetos, aperturas y cierres de puertas, alarmar, 
                            barreras, cercos perimetrales, entre otros. Recorremos una amplia capacidad
                             y tecnología para facilitar el camino hacía el futurismo. <br /><br />
                             Tenemos el personal capacitado y tecnología experta en el 
                             control automático y eléctrico  de las distintas funciones.
                            </p>
                            <Link to={'../pdf/TelecomuniacionesDomotica.pdf'} target='_blank'>
                                <ButtonDark toDo='Brochure en PDF' type='button'/>
                            </Link>
                        </div>

                    </div>


                    <div className='gallery__cont mt-4'> 

                        <div className='gallery__cont--column33'>
                            <img    onClick={ () => handleShow('../images/divisiones/telecomunicaciones/seguridad-electronica-y-domotica/automatizacion-y-domotica/imagen-1.jpg', 'Video Vigilancia') } src='../images/divisiones/telecomunicaciones/seguridad-electronica-y-domotica/automatizacion-y-domotica/imagen-1.jpg' alt='Video Vigilancia.' loading='lazy'/>
                            <img    onClick={ () => handleShow('../images/divisiones/telecomunicaciones/seguridad-electronica-y-domotica/automatizacion-y-domotica/imagen-2.jpg', 'Video Vigilancia') } src='../images/divisiones/telecomunicaciones/seguridad-electronica-y-domotica/automatizacion-y-domotica/imagen-2.jpg' alt='Video Vigilancia.' loading='lazy'/>

                        </div>

                        <div className='gallery__cont--column33'>
                            <img    onClick={ () => handleShow('../images/divisiones/telecomunicaciones/seguridad-electronica-y-domotica/automatizacion-y-domotica/imagen-3.jpg', 'Video Vigilancia') } src='../images/divisiones/telecomunicaciones/seguridad-electronica-y-domotica/automatizacion-y-domotica/imagen-3.jpg' alt='Video Vigilancia.' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/telecomunicaciones/seguridad-electronica-y-domotica/automatizacion-y-domotica/imagen-4.jpg', 'Video Vigilancia') } src='../images/divisiones/telecomunicaciones/seguridad-electronica-y-domotica/automatizacion-y-domotica/imagen-4.jpg' alt='Video Vigilancia.' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column33'>
                            <img    onClick={ () => handleShow('../images/divisiones/telecomunicaciones/seguridad-electronica-y-domotica/automatizacion-y-domotica/imagen-5.jpg', 'Video Vigilancia') } src='../images/divisiones/telecomunicaciones/seguridad-electronica-y-domotica/automatizacion-y-domotica/imagen-5.jpg' alt='Video Vigilancia.' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/telecomunicaciones/seguridad-electronica-y-domotica/automatizacion-y-domotica/imagen-6.jpg', 'Video Vigilancia') } src='../images/divisiones/telecomunicaciones/seguridad-electronica-y-domotica/automatizacion-y-domotica/imagen-6.jpg' alt='Video Vigilancia.' loading='lazy' />
                        </div>
                
                    </div>

                </div>
            </div>

            <div>
                <h3 className='divisionItem__bajada--tit text-center'>
                    Otras Tareas
                </h3>
                <div className='row divisionItem__proyectosDestacados p-0'> 
                    <DivisionBanner 
                        division = {'Dts y Das'}
                        imgUrl = {'../images/divisiones/telecomunicaciones/dts-y-das.jpg'}
                        linkUrl = {'/telecomunicaciones/dts-das/'}
                    /> 
                   <DivisionBanner 
                        division = {'FTTx'}
                        imgUrl = {'../images/divisiones/telecomunicaciones/fttx.jpg'}
                        linkUrl = {'/telecomunicaciones/fttx/'}
                    /> 
                    <DivisionBanner 
                        division = {'Networking'}
                        imgUrl = {'../images/divisiones/telecomunicaciones/networking.jpg'}
                        linkUrl = {'/telecomunicaciones/networking/'}
                    /> 
                    <DivisionBanner 
                        division = {'Video Vigilancia'}
                        imgUrl = {'../images/divisiones/telecomunicaciones/videovigilancia-domotica.jpg'}
                        linkUrl = {'/telecomunicaciones/video-vigilancia/'}
                    /> 
                </div>
            </div>
            
            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default AutomatizacionDomotica