import React, {useState, useEffect} from 'react'
import Loading from '../../Commons/Loading/Loading';
import FooterFormulario from '../../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import '../DivisionesDetalles.scss'
import DivisionBanner from '../../DivisionContainer/DivisionBanner/DivisionBanner';
import { Modal } from 'react-bootstrap'


const Veredas = () => {


    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (paramUrl, paramDesc) => {
        
        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)
        
        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return (

        <section className='container-fluid p-0 containerBlock' > 


            <Modal
              show={show}
              size='lg'
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Veredas.</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
                </Modal.Body>

                {/* <Modal.Footer>
                    <small >{txtDes}</small>
                </Modal.Footer> */}
            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-reparacion-de-tanques.jpg" 
                    alt="Reparacion de Tanques." 
                    loading='lazy'
                    className='divisionItem__container--img'    
                />

                <div className='divisionItem__container--textos' >
                    <h3 className='divisionItem__container--header'>DIVISION</h3>
                    <h1 className='divisionItem__container--title'>OBRAS CIVILES INDUSTRIALES</h1>
                    
                </div>

            </div>

            <div className='container-fluid divisionItem__detail'>
                <div className='container animated fast fadeIn'>
                    <Link to={'/obras-civiles-industriales/'}>
                    <small> 
                        <i className="fas fa-angle-double-left" style={{'paddingBottom':'15px', 'paddingRight': '10px'}} ></i> 
                        Volver a Division</small>
                    </Link>

                    <div className='row'>
                        {/* <div className='col-md-6'> 
                            
                            <img 
                                src="../images/divisiones/obras-civiles-industriales/trabajos-varios/obras-civiles-01.jpg" 
                                alt="Tanque de 50.000m3. con la obra terminada."
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            /> 

                        </div> */}

                        <div className='col-md-12 divisionItem__detail--block divisionItem__detail--blockObras'>
                            <h1 className='divisionItem__detail--subtitle' >Veredas</h1>
                            <p  className='divisionItem__detail--text' > 
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. A ex earum magni vero aut sapiente ullam sunt dicta porro quo, culpa quaerat enim officiis! Assumenda suscipit sint beatae ipsum provident!
                            </p>
                            
                        </div>

                    </div>
                        
                    <div className='gallery__cont'>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/veredas/veredas-01.jpg', 'Veredas.') } src='../images/divisiones/obras-civiles-industriales/veredas/veredas-01.jpg' alt='Obra de Veredas.' loading='lazy'/>
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/veredas/veredas-02.jpg', 'Veredas.') } src='../images/divisiones/obras-civiles-industriales/veredas/veredas-02.jpg' alt='Obra de Veredas.' loading='lazy' /> 
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/veredas/veredas-09.jpg', 'Veredas.') } src='../images/divisiones/obras-civiles-industriales/veredas/veredas-09.jpg' alt='Obra de Veredas.' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/veredas/veredas-03.jpg', 'Veredas.') } src='../images/divisiones/obras-civiles-industriales/veredas/veredas-03.jpg' alt='Obra de Veredas.' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/veredas/veredas-05.jpg', 'Veredas.') } src='../images/divisiones/obras-civiles-industriales/veredas/veredas-05.jpg' alt='Obra de Veredas.' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/veredas/veredas-07.jpg', 'Veredas.') } src='../images/divisiones/obras-civiles-industriales/veredas/veredas-07.jpg' alt='Obra de Veredas.' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/veredas/veredas-08.jpg', 'Veredas.') } src='../images/divisiones/obras-civiles-industriales/veredas/veredas-08.jpg' alt='Obra de Veredas.' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/veredas/veredas-06.jpg', 'Veredas.') } src='../images/divisiones/obras-civiles-industriales/veredas/veredas-06.jpg' alt='Obra de Veredas.' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/veredas/veredas-11.jpg', 'Veredas.') } src='../images/divisiones/obras-civiles-industriales/veredas/veredas-11.jpg' alt='Obra de Veredas.' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/veredas/veredas-10.jpg', 'Veredas.') } src='../images/divisiones/obras-civiles-industriales/veredas/veredas-10.jpg' alt='Obra de Veredas.' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/veredas/veredas-04.jpg', 'Veredas.') } src='../images/divisiones/obras-civiles-industriales/veredas/veredas-04.jpg' alt='Obra de Veredas.' loading='lazy' />
                        </div> 
                    
                    </div>
                </div>
            </div>
            <div>

                <h3 className='divisionItem__bajada--tit text-center'>
                   Otras Divisiones
                </h3>

                <div className='row divisionItem__proyectosDestacados p-0'> 
                    <DivisionBanner 
                        division = {'Piso: Reparación y Construcción'}
                        imgUrl = {'../images/divisiones/division-banners/divisionBanners-piso-reparacion.jpg'}
                        linkUrl = {'/obras-civiles-industriales/piso-teparación-y-construccion/'}
                    /> 
                    <DivisionBanner 
                        division = {'Techo: Reparación y Construcción'}
                        imgUrl = {'../images/divisiones/division-banners/divisionBanners-techo-reparacion.jpg'}
                        linkUrl = {'/obras-civiles-industriales/techo-reparacion-y-construccion/'}
                    /> 
                    <DivisionBanner 
                        division = {'Still Pipe '}
                        imgUrl = {'../images/divisiones/division-banners/divisionBanners-still-pipe.jpg'}
                        linkUrl = {'/obras-civiles-industriales/still-pipe/'}
                    /> 
                    <DivisionBanner 
                        division = {'P.E.A.D'}
                        imgUrl = {'../images/divisiones/division-banners/divisionBanners-pead.jpg'}
                        linkUrl = {'/obras-civiles-industriales/pead/'}
                    /> 
                </div>

            </div>

            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default Veredas