import React, { useState, useEffect } from 'react'
import Loading from '../Commons/Loading/Loading';
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap'

import '../../Styles/main.scss'
import '../Divisiones/DivisionesDetalles.scss'
import ObraThumb from '../Commons/ObraThumb/ObraThumb';
import ObrasRealizadas from '../Commons/ObrasRealizadas/ObrasRealizadas';

const Spool = () => {


    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = (paramUrl, paramDesc) => {

        setImgUrl(paramUrl)
        setShow(true)

        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    })

    if (loading) { return <Loading /> }

    return (

        <section className='container-fluid p-0 containerBlock' >


            <Modal
                show={show}
                size='lg'
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Spool.</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
                </Modal.Body>
                {/* 
              <Modal.Footer>
                <small >{txtDes}</small>
              </Modal.Footer> */}

            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-aislaciones-termicas.jpg"
                    alt="Obras, andamios."
                    loading='lazy'
                    className='divisionItem__container--img'
                />

                <div className='divisionItem__container--textos divisionItem__container--textos-obras' >
                    <h3 className='divisionItem__container--header'>OBRAS</h3>
                    <h1 className='divisionItem__container--title'>Spool</h1>
                </div>

            </div>

            <div className='container-fluid divisionItem__detail'>
                <div className='container animated fast fadeIn'>
                    <Link to={'/obras/'}>
                        <small>
                            <i className="fas fa-angle-double-left" style={{ 'paddingBottom': '15px', 'paddingRight': '10px' }} ></i>
                            Volver a Obras
                        </small>
                    </Link>

                    <div className='row'>

                        <div className='col-md-5'>
                            <img
                                src="../images/obras/spool/spool.jpg"
                                alt="Spool."
                                // className='d-block w-100 divisionItem__detail--header'
                                className='d-block w-100'
                                loading='lazy'
                            />
                        </div>

                        <div className='col-md-7 divisionItem__detail--block'>
                            <h1 className='divisionItem__detail--title' >Prefabricado y pintura de cañerías.</h1>
                            <h3 className='divisionItem__detail--subtitle' >
                                <i className="fas fa-map-marker-alt" style={{ 'paddingBottom': '5px', 'paddingRight': '10px' }}></i>
                                Planta Deshidratadora e Inyección de agua.
                            </h3>
                            <p className='divisionItem__detail--text' >
                                Trabajos de prefabricados de cañerías y de Recubrimiento Superficial. Pintura de spool´s de cañerías.
                            </p>
                            <div className='row obras__box--short'>
                                <div className='col col-6 obras__card'>
                                    <ObraThumb
                                        ObraNum={'17000'}
                                        ObraVal={''}
                                        ObraItem={null}
                                        ObraDet={'Pulgadas diametrales'}
                                    />
                                </div>
                                <div className='col col-6 obras__card'>
                                    <ObraThumb
                                        ObraNum={'2“ a 24”'}
                                        ObraVal={''}
                                        ObraItem={null}
                                        ObraDet={'Diámetros de cañería'}
                                        ObrasShort={'obras__cont--numShort'}
                                    />
                                </div>
                            </div>

                            <h1 className='divisionItem__detail--title' >Arenado y pintura de Spool.</h1>
                            <h3 className='divisionItem__detail--subtitle' >
                                <i className="fas fa-map-marker-alt" style={{ 'paddingBottom': '5px', 'paddingRight': '10px' }}></i>
                                Planta deshidratadora e inyección de agua.
                            </h3>
                            <p className='divisionItem__detail--text' >
                                Se realizaron trabajos en base operativa de arenado y pintura de spools.<br />
                                Los caños fueron entre Ø88,9 mm y Ø273 mm. Para la ejecución se utilizó arena y pintura certificada. <br />
                                Se mantuvo seguimiento de calidad y ensayo de resistencia a la tracción con equipo certificado y se ocupó personal operativo calificado.
                            </p>
                            <div className='row obras__box--short'>
                                <div className='col col-6 obras__card'>
                                    <ObraThumb
                                        ObraNum={'300'}
                                        ObraVal={'m²'}
                                        ObraItem={null}
                                        ObraDet={'De pintura'}
                                    />
                                </div>
                                <div className='col col-6 obras__card'>
                                    <ObraThumb
                                        ObraNum={'350'}
                                        ObraVal={'m'}
                                        ObraItem={null}
                                        ObraDet={'De caño'}
                                    />
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className='gallery__cont'>

                        <div className='gallery__cont--column33'>
                            <img onClick={() => handleShow('../images/obras/spool/spool-01.jpg', 'Aesa Spool.')} src='../images/obras/spool/spool-01.jpg' alt='Aesa Spool' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/spool/spool-02.jpg', 'Aesa Spool.')} src='../images/obras/spool/spool-02.jpg' alt='Aesa Spool' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column33'>
                            <img onClick={() => handleShow('../images/obras/spool/spool-03.jpg', 'Aesa Spool.')} src='../images/obras/spool/spool-03.jpg' alt='Aesa Spool' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/spool/spool-04.jpg', 'Aesa Spool.')} src='../images/obras/spool/spool-04.jpg' alt='Aesa Spool' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column33'>
                            <img onClick={() => handleShow('../images/obras/spool/spool-05.jpg', 'Aesa Spool.')} src='../images/obras/spool/spool-05.jpg' alt='Aesa Spool' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/spool/spool-06.jpg', 'Aesa Spool.')} src='../images/obras/spool/spool-06.jpg' alt='Aesa Spool' loading='lazy' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='otrasObras__container'>
                <ObrasRealizadas cantObras={5} excluir={6} />
            </div>
            <FooterFormulario className='mt-5' />
        </section>
    )
}

export default Spool