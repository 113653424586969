import React, { useState, useEffect } from 'react'
import Loading from '../Commons/Loading/Loading';
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import DivisionBanner from '../DivisionContainer/DivisionBanner/DivisionBanner';
import { Modal } from 'react-bootstrap'

import '../../Styles/main.scss'
import '../Divisiones/DivisionesDetalles.scss'
import ObrasRealizadas from '../Commons/ObrasRealizadas/ObrasRealizadas';

const EnfriadorDeAceite = () => {


    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = (paramUrl, paramDesc) => {

        setImgUrl(paramUrl)
        setShow(true)

        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    })

    if (loading) { return <Loading /> }

    return (

        <section className='container-fluid p-0 containerBlock' >


            <Modal
                show={show}
                size='lg'
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Enfriador de Aceite.</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
                </Modal.Body>
                {/* 
              <Modal.Footer>
                <small >{txtDes}</small>
              </Modal.Footer> */}

            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-aislaciones-termicas.jpg"
                    alt="Obras, andamios."
                    loading='lazy'
                    className='divisionItem__container--img'
                />

                <div className='divisionItem__container--textos divisionItem__container--textos-obras' >
                    <h3 className='divisionItem__container--header'>OBRAS</h3>
                    <h1 className='divisionItem__container--title'>Enfriador de aceite</h1>
                </div>

            </div>

            <div className='container-fluid divisionItem__detail'>
                <div className='container animated fast fadeIn'>
                    <Link to={'/obras/'}>
                        <small>
                            <i className="fas fa-angle-double-left" style={{ 'paddingBottom': '15px', 'paddingRight': '10px' }} ></i>
                            Volver a Obras
                        </small>
                    </Link>

                    <div className='row'>

                        <div className='col-md-6'>
                            <img
                                src="../images/obras/enfriador-aceite/enfriador-aceite-04.jpg"
                                alt="Aislación de piping en PIUS."
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            />
                        </div>

                        <div className='col-md-6 divisionItem__detail--block'>
                            <h1 className='divisionItem__detail--title' >Enfriador de aceite</h1>

                            <p className='divisionItem__detail--text' >
                                Se realiza el mantenimiento y reparación de enfriadores de aceite en nuestra base operativa.<br /><br />
                                Nuestro personal operativo se encarga de desarmar el cilindro, sondear cada tubo y reparar las pérdidas que surjan luego de las pruebas hidráulicas correspondientes.<br /><br />
                                Finalizando con la limpieza, armado de piezas y pintura sintetica.
                            </p>
                        </div>

                    </div>

                    <div className='gallery__cont'>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/enfriador-aceite/enfriador-aceite-01.jpg', 'Enfriador de Aceite.')} src='../images/obras/enfriador-aceite/enfriador-aceite-01.jpg' alt='Enfriador de Aceite' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/enfriador-aceite/enfriador-aceite-02.jpg', 'Enfriador de Aceite.')} src='../images/obras/enfriador-aceite/enfriador-aceite-02.jpg' alt='Enfriador de Aceite' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/enfriador-aceite/enfriador-aceite-03.jpg', 'Enfriador de Aceite.')} src='../images/obras/enfriador-aceite/enfriador-aceite-03.jpg' alt='Enfriador de Aceite' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/enfriador-aceite/enfriador-aceite-04.jpg', 'Enfriador de Aceite.')} src='../images/obras/enfriador-aceite/enfriador-aceite-04.jpg' alt='Enfriador de Aceite' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/enfriador-aceite/enfriador-aceite-05.jpg', 'Enfriador de Aceite.')} src='../images/obras/enfriador-aceite/enfriador-aceite-05.jpg' alt='Enfriador de Aceite' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/enfriador-aceite/enfriador-aceite-06.jpg', 'Enfriador de Aceite.')} src='../images/obras/enfriador-aceite/enfriador-aceite-06.jpg' alt='Enfriador de Aceite' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/enfriador-aceite/enfriador-aceite-07.jpg', 'Enfriador de Aceite.')} src='../images/obras/enfriador-aceite/enfriador-aceite-07.jpg' alt='Enfriador de Aceite' loading='lazy' />
                        </div>
                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/enfriador-aceite/enfriador-aceite-08.jpg', 'Enfriador de Aceite.')} src='../images/obras/enfriador-aceite/enfriador-aceite-08.jpg' alt='Enfriador de Aceite' loading='lazy' />
                        </div>
                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/enfriador-aceite/enfriador-aceite-09.jpg', 'Enfriador de Aceite.')} src='../images/obras/enfriador-aceite/enfriador-aceite-09.jpg' alt='Enfriador de Aceite' loading='lazy' />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <ObrasRealizadas cantObras={5} excluir={1} />
            </div>

            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default EnfriadorDeAceite