import React , {useEffect, useState}    from 'react'
import Carousel                         from '../Commons/Carousel/Carousel'
import DivisionContainer                from '../DivisionContainer/DivisionContainer'
import BannerAniversario                from '../Commons/BannerAniversario/BannerAniversario'
import FooterFormulario                 from '../Commons/FooterFormulario/FooterFormulario'
import ObrasRealizadas                  from '../Commons/ObrasRealizadas/ObrasRealizadas'
import Loading                          from '../Commons/Loading/Loading'
import './Home.scss'
// import {Slideshow, Slide, TextoSlide} from '../Slideshow/Slideshow'

const Home = () => {

    const [loading, setLoading] = useState(true);

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 2000)
    })

    if(loading) {return <Loading />}

    return (
        <div className='container-fluid homeContainer'>
            
            <Carousel />
            <DivisionContainer />
            <BannerAniversario />
            <ObrasRealizadas pageName={"HOME"} cantObras={4}/>
            <FooterFormulario />
            
        </div>
    )
}

export default Home
