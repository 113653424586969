import React, { useState, useEffect } from 'react'
import Loading from '../Commons/Loading/Loading';
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import '../Divisiones/DivisionesDetalles.scss'
import { Modal } from 'react-bootstrap'
import ObraThumb from '../Commons/ObraThumb/ObraThumb';
import ObrasRealizadas from '../Commons/ObrasRealizadas/ObrasRealizadas';


const TanqueTP20 = () => {


    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = (paramUrl, paramDesc) => {

        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)

        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    })

    if (loading) { return <Loading /> }

    return (

        <section className='container-fluid p-0 containerBlock' >


            <Modal
                show={show}
                size='lg'
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Reparación Tanque 160 m³</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
                </Modal.Body>

                <Modal.Footer>
                    <small >{txtDes}</small>
                </Modal.Footer>

            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-obras-civiles.jpg"
                    alt="Obras, andamios."
                    loading='lazy'
                    className='divisionItem__container--img'
                />

                <div className='divisionItem__container--textos divisionItem__container--textos-obras' >
                    <h3 className='divisionItem__container--header'>OBRAS</h3>
                    <h1 className='divisionItem__container--title'>Reparación de Tanque</h1>
                </div>

            </div>

            <div className='container-fluid obrasItem__detail'>
                <div className='container animated fast fadeIn'>
                    <Link to={'/obras/'}>
                        <small>
                            <i className="fas fa-angle-double-left" style={{ 'paddingBottom': '15px', 'paddingRight': '10px' }} ></i>
                            Volver a Obras
                        </small>
                    </Link>

                    <div className='row'>

                        <div className='col-md-6'>
                            <img
                                src="../images/obras/tanque-tk-tp20/tanque-tk-tp20-01.jpg"
                                alt="Reparación Tanque Tk TP20."
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            />
                        </div>

                        <div className='col-md-6 divisionItem__detail--block'>
                            <h1 className='divisionItem__detail--title' >Reparación Tanque 160 m³</h1>
                            <h3 className='divisionItem__detail--subtitle' >Myburg 3 – Manantiales Behr</h3>

                            <p className='divisionItem__detail--text pt-2' >
                                Reparación de tanque de 3000 mm de altura, 10000 mm de diámetro y una capacidad de 235 m3.<br /><br />
                                Las tareas realizadas involucraron cambio de columna central, cambio de conexiones de techo, cambio total de piso y reparación de regla de medición.

                            </p>

                        </div>

                    </div>

                    <div className='row obras__box'>
                        <div className='col-6 col-sm-3 col-md-6 col-lg-3 obras__card'>
                            <ObraThumb
                                ObraNum={'190'}
                                ObraVal={'m2'}
                                ObraItem={null}
                                ObraDet={'Pintura'}
                            />
                        </div>
                        <div className='col-6 col-sm-3 col-md-6 col-lg-3 obras__card'>
                            <ObraThumb
                                ObraNum={'200'}
                                ObraVal={'mL'}
                                ObraItem={''}
                                ObraDet={'Soldadura'}
                            />
                        </div>
                        <div className='col-6 col-sm-3 col-md-6 col-lg-3 obras__card'>
                            <ObraThumb
                                ObraXL={true}
                                ObraNum={'100'}
                                ObraNum1={'4972'}
                                ObraVal={'m2'}
                                ObraVal1={'Kg'}
                                ObraItem={null}
                                ObraDet={'chapas'}
                            />
                        </div>
                        <div className='col-6 col-sm-3 col-md-6 col-lg-3 obras__card'>
                            <ObraThumb
                                ObraNum={'200'}
                                ObraVal={'m2'}
                                ObraItem={null}
                                ObraDet={'Aislación (chapa trapezoidal)'}
                            />
                        </div>
                    </div>

                    <div className='gallery__cont'>

                        <div className='gallery__cont--column33'>
                            <img onClick={() => handleShow('../images/obras/tanque-tk-tp20/tanque-tk-tp20-02.jpg', 'Reparación Tanque Tk TP20')} src='../images/obras/tanque-tk-tp20/tanque-tk-tp20-02.jpg' alt='Tanque de 5.000m3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/tanque-tk-tp20/tanque-tk-tp20-04.jpg', 'Reparación Tanque Tk TP20')} src='../images/obras/tanque-tk-tp20/tanque-tk-tp20-04.jpg' alt='Tanque de 5.000m3' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column33'>
                            <img onClick={() => handleShow('../images/obras/tanque-tk-tp20/tanque-tk-tp20-03.jpg', 'Reparación Tanque Tk TP20')} src='../images/obras/tanque-tk-tp20/tanque-tk-tp20-03.jpg' alt='Tanque de 5.000m3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/tanque-tk-tp20/tanque-tk-tp20-05.jpg', 'Reparación Tanque Tk TP20')} src='../images/obras/tanque-tk-tp20/tanque-tk-tp20-05.jpg' alt='Tanque de 5.000m3' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column33'>
                            <img onClick={() => handleShow('../images/obras/tanque-tk-tp20/tanque-tk-tp20-04.jpg', 'Reparación Tanque Tk TP20')} src='../images/obras/tanque-tk-tp20/tanque-tk-tp20-04.jpg' alt='Tanque de 5.000m3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/tanque-tk-tp20/tanque-tk-tp20-06.jpg', 'Reparación Tanque Tk TP20')} src='../images/obras/tanque-tk-tp20/tanque-tk-tp20-06.jpg' alt='Tanque de 5.000m3' loading='lazy' />
                        </div>



                    </div>
                </div>
            </div>
            <div className='otrasObras__container'>
                <ObrasRealizadas cantObras={5} excluir={1} />
            </div>
            <FooterFormulario className='mt-5' />
        </section>
    )
}

export default TanqueTP20