import React, {useState, useEffect} from 'react'
import Loading from '../Commons/Loading/Loading';
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import '../Divisiones/DivisionesDetalles.scss'
import DivisionBanner from '../DivisionContainer/DivisionBanner/DivisionBanner';
import { Modal } from 'react-bootstrap'
import ObraThumb from '../Commons/ObraThumb/ObraThumb';


const EnlacesUrbanosPuntoAPunto = () => {


    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (paramUrl, paramDesc) => {
        
        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)
        
        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return (

        <section className='container-fluid p-0 containerBlock' > 


            <Modal
              show={show}
              size='lg'
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Enlaces Urbanos punto a punto</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
              </Modal.Body>

              <Modal.Footer>
                <small >{txtDes}</small>
              </Modal.Footer>

            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-telecomunicaciones.jpg" 
                    alt="Obras, andamios." 
                    loading='lazy'
                    className='divisionItem__container--img'    
                />

                <div className='divisionItem__container--textos divisionItem__container--textos-obras' >
                    <h3 className='divisionItem__container--header'>OBRAS</h3>
                    <h1 className='divisionItem__container--title'>TELECOMUNICACIONES</h1>
                </div>

            </div>

            <div className='container-fluid obrasItem__detail'>
                <div className='container animated fast fadeIn'>
                    <Link to={'/obras/'}>
                        <small> 
                        <i className="fas fa-angle-double-left" style={{'paddingBottom':'15px', 'paddingRight': '10px'}} ></i> 
                        Volver a Obras
                        </small>
                    </Link>

                    <div className='row'>

                        <div className='col-md-4'>     
                            <img 
                                src="../images/obras/telecomunicaciones/enlaces-urbanos-punto-a-punto/enlaces-punto-a-punto-00.jpg" 
                                alt="Enlaces Urbanos punto a punto de Agua."
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            /> 
                        </div>

                        <div className='col-md-8 divisionItem__detail--block'>
                            <h1 className='divisionItem__detail--title' >Enlaces Urbanos punto a punto</h1>
                            
                            <p  className='divisionItem__detail--text pt-2' > 
                                Gobierno de la Provincia de Corrientes, Ciudad de Corrientes.<br />
                                Enlaces aéreos punto a punto entre edificios gubernamentales.<br />
                                Tendido de 63 km FO dentro del casco Histórico de la Ciudad.<br />
                            </p>

                            <div className='row obras__box justify-content-center' style={{ marginTop: '40px'}}>
                                <div className='col-6 col-sm-4 col-md-6 col-lg-4 obras__card'>
                                    <ObraThumb  
                                        ObraNum  = {'63'}
                                        ObraVal  = {'KM'}
                                        ObraItem = {''}
                                        ObraDet  = {'Cable de FO PKP'}
                                    />
                                </div>
                                <div className='col-6 col-sm-4 col-md-6 col-lg-4 obras__card'>
                                    <ObraThumb  
                                        ObraNum  = {'43'}
                                        ObraVal ={''}
                                        ObraItem = {''}
                                        ObraDet  = {'Edificios Públicos'}
                                    />
                                </div>
                                <div className='col-6 col-sm-4 col-md-6 col-lg-4 obras__card'>
                                    <ObraThumb  
                                        ObraNum  = {'2046'}
                                        ObraVal ={''}
                                        ObraItem = {''}
                                        ObraDet  = {'Fusiones de FO en ODF'}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
            
                    <div className='gallery__cont justify-content-center'>
                        <div className='gallery__cont--column25'>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/enlaces-urbanos-punto-a-punto/enlaces-punto-a-punto-01.jpg', 'Enlaces Urbanos punto a punto') } src='../images/obras/telecomunicaciones/enlaces-urbanos-punto-a-punto/enlaces-punto-a-punto-01.jpg' alt='Enlaces Urbanos punto a punto' loading='lazy'/>
                        </div>
                        <div className='gallery__cont--column33'>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/enlaces-urbanos-punto-a-punto/enlaces-punto-a-punto-02.jpg', 'Enlaces Urbanos punto a punto') } src='../images/obras/telecomunicaciones/enlaces-urbanos-punto-a-punto/enlaces-punto-a-punto-02.jpg' alt='Enlaces Urbanos punto a punto' loading='lazy' /> 
                        </div>
                        <div className='gallery__cont--column33'>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/enlaces-urbanos-punto-a-punto/enlaces-punto-a-punto-03.jpg', 'Enlaces Urbanos punto a punto') } src='../images/obras/telecomunicaciones/enlaces-urbanos-punto-a-punto/enlaces-punto-a-punto-03.jpg' alt='Enlaces Urbanos punto a punto' loading='lazy'/>
                        </div>
                    </div>
                                        
                </div>
            </div>

            <div className='otrasObras__container'>

                <h3 className='divisionItem__bajada--tit text-center'>
                   Otras Obras
                </h3>

                <div className='row divisionItem__proyectosDestacados p-0'> 
                    <DivisionBanner 
                        division = {'Enlaces en Rada Tilly'}
                        imgUrl = {'../images/obras/telecomunicaciones/enlaces-en-rada-tilly.jpg'}
                        linkUrl = {'/obras/enlaces-en-rada-tilly/'}
                    /> 
                    <DivisionBanner 
                        division = {'FO para Largos Vanos'}
                        imgUrl = {'../images/obras/telecomunicaciones/fo-para-largos-vanos.jpg'}
                        linkUrl = {'/obras/fo-para-largos-vanos/'}
                    /> 
                    <DivisionBanner 
                        division = {'Red DWDM Gran Ancho de Banda FO Zero Pico de Agua.'}
                        imgUrl = {'../images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua.jpg'}
                        linkUrl = {'/obras/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/'}
                    />
                    <DivisionBanner 
                        division = {'Coop de Laguna de los Padres'}
                        imgUrl = {'../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres.jpg'}
                        linkUrl = {'/obras/coop-de-laguna-de-los-padres/'}
                    /> 
                </div>

            </div>

            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default EnlacesUrbanosPuntoAPunto