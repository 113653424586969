import React, {useState, useEffect} from 'react';
import DivisionContainerInternas from '../DivisionContainer/DivisionContainerInternas'
import Loading from '../Commons/Loading/Loading';
import { Link } from 'react-router-dom';
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'
import { Modal } from 'react-bootstrap';

import './Divisiones.scss';

const Radiadores = () => {

    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (paramUrl, paramDesc) => {
        
        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)
        
        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })


    if(loading) {return <Loading />}

    return    <section className='container-fluid p-0 pb-0 display-block'>
                
                <Modal
                    show={show}
                    size='lg'
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    >
                    <Modal.Header closeButton>
                        <Modal.Title>Radiadores</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
                    </Modal.Body>

                    <Modal.Footer>
                        <small >{txtDes}</small>
                    </Modal.Footer>
                </Modal>
                    
                <div className='division__container animated fast fadeIn'>
                   
                    <img 
                        src="../images/headers/headers-radiadores.jpg" 
                        alt="Radiadores industriales." 
                        loading='lazy'
                        className='division__container--img division__container--imgM'
                    />

                    <div className='division__container--textos' >
                        <h3 className='division__container--header'>DIVISION</h3>
                        <h1 className='division__container--title'>Radiadores</h1>
                        <p className='division__container--text' style={{textAlign: 'justify'}}>
                        La unidad de Negocio de Radiadores tiene más de 30 años de experiencia en esta área, se especializa en cuerpos industriales y automotrices, paneles, enfriadores de aceite, Intercooler, entre otros.<br />
                        </p>
                    </div>

                </div>

                <div className='divisionItem__detail  animated fast fadeIn'>
                    <div className='container container__divisiones'>
                        <Link to={'/construccion-reparacion-tanques/'}>
                        <small> 
                            <i className="fas fa-angle-double-left" style={{'paddingBottom':'15px', 'paddingRight': '10px'}} ></i> 
                            Volver al Inicio</small>
                        </Link>

                        <div className='row'>
                            <div className='col-md-6'> 
                                <img 
                                    src="../images/divisiones/radiadores/radiadores.jpg" 
                                    alt="30 años de experiencia en el negocio de Radiadores."
                                    className='d-block w-100 divisionItem__detail--header'
                                    loading='lazy'
                                /> 
                            </div>

                            <div className='col-md-6 divisionItem__detail--block'>
                                <h1 className='divisionItem__detail--title' >Radiadores</h1>
                                <p  className='divisionItem__detail--text' > 
                                    Algunas de las tareas que realizamos en nuestro taller son:
                                    <ul>
                                        <li>Desarme completo del radiador, desmontando parilla laterales y tanques.</li>
                                        <li>Limpieza de las partes con productos acordes a los materiales con los que está fabricado cada equipo y en función del tipo de suciedad que sea necesario remover</li>
                                        <li>Detección de fugas mediante bancos de pruebas </li>
                                        <li>Reparación acorde al tipo de equipo: soldadura con bronce, plata, estaño, aluminio, reemplazo de orings, etc.</li>
                                        <li>Enderezado de las láminas para que tenga mejor pase de aire y permita buena refrigeración.</li>
                                        <li>Arenado y Pintura de terminación según las indicaciones del cliente.</li>
                                        <li>Mantención preventiva de equipos.</li>
                                        <li>Fabricación e instalación de piezas y partes (Ej: Paneles).</li>
                                        <li>Montaje y desmontaje de equipos.</li>
                                        <li>Evaluación y desarollo de proyectos.</li>
                                    </ul>
                                </p> 
                           
                        </div>


                        <div className='gallery__cont'>

                            <div className='gallery__cont--column'>
                                <img    onClick={ () => handleShow('../images/divisiones/radiadores/radiadores-01.jpg', 'Radiadores de distintos tamaños.') } src='../images/divisiones/radiadores/radiadores-01.jpg' alt='Radiadores' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/divisiones/radiadores/radiadores-02.jpg', 'Radiadores de distintos tamaños.') } src='../images/divisiones/radiadores/radiadores-02.jpg' alt='Radiadores' loading='lazy'/>
                                <img    onClick={ () => handleShow('../images/divisiones/radiadores/radiadores-05.jpg', 'Radiadores de distintos tamaños.') } src='../images/divisiones/radiadores/radiadores-05.jpg' alt='Radiadores' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/obras/radiador-guascor/radiador-guascor-05.jpg', 'Radiadores de distintos tamaños.') } src='../images/obras/radiador-guascor/radiador-guascor-05.jpg' alt='Radiadores' loading='lazy' />
                            </div>

                            <div className='gallery__cont--column'>
                                <img    onClick={ () => handleShow('../images/divisiones/radiadores/radiadores-06.jpg', 'Radiadores de distintos tamaños.') } src='../images/divisiones/radiadores/radiadores-06.jpg' alt='Radiadores' loading='lazy' /> 
                                <img    onClick={ () => handleShow('../images/divisiones/radiadores/radiadores-07.jpg', 'Radiadores de distintos tamaños.') } src='../images/divisiones/radiadores/radiadores-07.jpg' alt='Radiadores' loading='lazy' /> 
                                <img    onClick={ () => handleShow('../images/divisiones/radiadores/radiadores-08.jpg', 'Radiadores de distintos tamaños.') } src='../images/divisiones/radiadores/radiadores-08.jpg' alt='Radiadores' loading='lazy' /> 
                                <img    onClick={ () => handleShow('../images/divisiones/radiadores/radiadores-11.jpg', 'Radiadores de distintos tamaños.') } src='../images/divisiones/radiadores/radiadores-11.jpg' alt='Radiadores' loading='lazy' /> 
                            </div>

                            <div className='gallery__cont--column'>
                            
                                <img    onClick={ () => handleShow('../images/divisiones/radiadores/radiadores-09.jpg', 'Radiadores de distintos tamaños.') } src='../images/divisiones/radiadores/radiadores-09.jpg' alt='Radiadores' loading='lazy' /> 
                                <img    onClick={ () => handleShow('../images/divisiones/radiadores/radiadores-10.jpg', 'Radiadores de distintos tamaños.') } src='../images/divisiones/radiadores/radiadores-10.jpg' alt='Radiadores' loading='lazy' /> 
                                <img    onClick={ () => handleShow('../images/obras/radiador-guascor/radiador-guascor-01.jpg', 'Radiadores de distintos tamaños.') } src='../images/obras/radiador-guascor/radiador-guascor-01.jpg' alt='Radiadores' loading='lazy' />
                            </div>

                            <div className='gallery__cont--column'>
                                <img    onClick={ () => handleShow('../images/divisiones/radiadores/radiadores-12.jpg', 'Radiadores de distintos tamaños.') } src='../images/divisiones/radiadores/radiadores-12.jpg' alt='Radiadores' loading='lazy' /> 
                                <img    onClick={ () => handleShow('../images/divisiones/radiadores/radiadores-03.jpg', 'Radiadores de distintos tamaños.') } src='../images/divisiones/radiadores/radiadores-03.jpg' alt='Radiadores' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/divisiones/radiadores/radiadores-04.jpg', 'Radiadores de distintos tamaños.') } src='../images/divisiones/radiadores/radiadores-04.jpg' alt='Radiadores' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/obras/radiador-guascor/radiador-guascor-02.jpg', 'Radiadores de distintos tamaños.') } src='../images/obras/radiador-guascor/radiador-guascor-02.jpg' alt='Radiadores' loading='lazy' />
                            </div>

                            <div className='gallery__cont--column'>
                                <img    onClick={ () => handleShow('../images/obras/radiador-guascor/radiador-guascor-03.jpg', 'Radiadores de distintos tamaños.') } src='../images/obras/radiador-guascor/radiador-guascor-03.jpg' alt='Radiadores' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/obras/radiador-guascor/radiador-guascor-04.jpg', 'Radiadores de distintos tamaños.') } src='../images/obras/radiador-guascor/radiador-guascor-04.jpg' alt='Radiadores' loading='lazy' />

                            </div> 
                        </div>
                    </div>
                </div>
                </div>

                <h3 className='division__bajada--tit text-center'>
                    Otras divisiones
                </h3>
                <div>
                    <DivisionContainerInternas divSelect={'radiadores'} />
                </div>

                
                <FooterFormulario className='mt-5' />
            </section>

};

export default Radiadores;
