import React, { useState, useEffect } from 'react'
import Loading from '../Commons/Loading/Loading';
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap'

import '../../Styles/main.scss'
import '../Divisiones/DivisionesDetalles.scss'
import ObraThumb from '../Commons/ObraThumb/ObraThumb';
import ObrasRealizadas from '../Commons/ObrasRealizadas/ObrasRealizadas';

const AislacionPiu = () => {


    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = (paramUrl, paramDesc) => {

        setImgUrl(paramUrl)
        setShow(true)

        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    })

    if (loading) { return <Loading /> }

    return (

        <section className='container-fluid p-0 containerBlock' >
            <Modal
                show={show}
                size='lg'
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Aislación de Piu.</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
                </Modal.Body>
                {/* 
              <Modal.Footer>
                <small >{txtDes}</small>
              </Modal.Footer> */}

            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-aislaciones-termicas.jpg"
                    alt="Obras, andamios."
                    loading='lazy'
                    className='divisionItem__container--img'
                />

                <div className='divisionItem__container--textos divisionItem__container--textos-obras' >
                    <h3 className='divisionItem__container--header'>OBRAS</h3>
                    <h1 className='divisionItem__container--title'>Aislación de Piu</h1>
                </div>

            </div>

            <div className='container-fluid divisionItem__detail'>
                <div className='container animated fast fadeIn'>
                    <Link to={'/obras/'}>
                        <small>
                            <i className="fas fa-angle-double-left" style={{ 'paddingBottom': '15px', 'paddingRight': '10px' }} ></i>
                            Volver a Obras
                        </small>
                    </Link>

                    <div className='row'>

                        <div className='col-md-6'>
                            <img
                                src="../images/obras/aislacion-piu/aislacion-piu-00.jpg"
                                alt="Aislación de piping en PIUS."
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            />
                        </div>

                        <div className='col-md-6 divisionItem__detail--block'>
                            <h1 className='divisionItem__detail--title' >Aislación de piping en PIUS, Satélites y parques de bombas de inyección de agua de secundaria.</h1>
                            <h3 className='divisionItem__detail--subtitle' >
                                <i className="fas fa-map-marker-alt" style={{ 'paddingBottom': '5px', 'paddingRight': '10px' }}></i>
                                Myburg V, El trebol, El Alba Valle y Cañadon Pérdido - Chubut
                            </h3>
                            <p className='divisionItem__detail--text' >
                                Se ejecutaron los trabajos de recubrimiento de aislación térmica.<br />
                                Para ello se diseñó, fabricó y montó cada elemento necesario como cajas, codos, patín, curvas, tee, niples y caños, más aislación.
                                Cada pieza es elaborada en la base operativa con operarios calificados.<br />
                                <strong>Se registraron todos los procesos de calidad como, certificación de chapa y lana, entre otros.</strong>
                            </p>
                        </div>

                    </div>

                    <div className='row obras__box  justify-content-center'>
                        <div className='col-6 col-sm-3 col-md-6 col-lg-3 obras__card'>
                            <ObraThumb
                                ObraNum={'350'}
                                ObraVal={'m2'}
                                ObraItem={null}
                                ObraDet={'Chapa de aluminio'}
                            />
                        </div>
                        <div className='col-6 col-sm-3 col-md-6 col-lg-3 obras__card'>
                            <ObraThumb
                                ObraNum={'340'}
                                ObraVal={'m²'}
                                ObraItem={null}
                                ObraDet={'Lana mineral'}
                            />
                        </div>
                        <div className='col-6 col-sm-3 col-md-6 col-lg-3 obras__card'>
                            <ObraThumb
                                ObraNum={'550'}
                                ObraVal={'UN'}
                                ObraItem={null}
                                ObraDet={'Prefabricados únicos'}
                            />
                        </div>
                    </div>

                    <div className='gallery__cont'>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/aislacion-piu/aislacion-piu-01.jpg', 'aislacion en piu.')} src='../images/obras/aislacion-piu/aislacion-piu-01.jpg' alt='aislacion lh3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/aislacion-piu/aislacion-piu-02.jpg', 'aislacion en piu.')} src='../images/obras/aislacion-piu/aislacion-piu-02.jpg' alt='aislacion lh3' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/aislacion-piu/aislacion-piu-05.jpg', 'aislacion en piu.')} src='../images/obras/aislacion-piu/aislacion-piu-05.jpg' alt='aislacion lh3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/aislacion-piu/aislacion-piu-01.jpg', 'aislacion en piu.')} src='../images/obras/aislacion-piu/aislacion-piu-03.jpg' alt='aislacion lh3' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/aislacion-piu/aislacion-piu-04.jpg', 'aislacion en piu.')} src='../images/obras/aislacion-piu/aislacion-piu-04.jpg' alt='aislacion lh3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/aislacion-piu/aislacion-piu-07.jpg', 'aislacion en piu.')} src='../images/obras/aislacion-piu/aislacion-piu-07.jpg' alt='aislacion lh3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/aislacion-piu/aislacion-piu-06.jpg', 'aislacion en piu.')} src='../images/obras/aislacion-piu/aislacion-piu-06.jpg' alt='aislacion lh3' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/aislacion-piu/aislacion-piu-08.jpg', 'aislacion en piu.')} src='../images/obras/aislacion-piu/aislacion-piu-08.jpg' alt='aislacion lh3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/aislacion-piu/aislacion-piu-09.jpg', 'aislacion en piu.')} src='../images/obras/aislacion-piu/aislacion-piu-09.jpg' alt='aislacion lh3' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/obras/aislacion-piu/aislacion-piu-10.jpg', 'aislacion en piu.')} src='../images/obras/aislacion-piu/aislacion-piu-10.jpg' alt='aislacion lh3' loading='lazy' />
                            <img onClick={() => handleShow('../images/obras/aislacion-piu/aislacion-piu-11.jpg', 'aislacion en piu.')} src='../images/obras/aislacion-piu/aislacion-piu-11.jpg' alt='aislacion lh3' loading='lazy' />
                        </div>

                    </div>
                </div>
            </div>
            <div>
                <ObrasRealizadas cantObras={5} excluir={5} />
            </div>

            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default AislacionPiu