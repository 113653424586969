import React from 'react'

const ObraThumb = ( props ) => {
  console.log("props.ObraItem", props.ObraItem)
  return (
    <div className='obras__cont'>
        {props.ObraXL 
          ? <div className={'obras__cont-inlineXL'}>
            <span className={`obras__cont--numXL ${props.ObrasShort}`}> { props.ObraNum }</span>
            <span className={'obras__cont--smallXL'}>{ props.ObraVal }</span>
            <span className={`obras__cont--numXL ${props.ObrasShort}`}> { props.ObraNum1 }</span>
            <span className={'obras__cont--smallXL'}>{ props.ObraVal1 }</span>
          </div>
          :<div className='obras__cont-inline'>
            <span className={`obras__cont--num ${props.ObrasShort}`}>{ props.ObraNum }</span>
            <span className='obras__cont--small'>{ props.ObraVal }</span>
          </div>
        }
        {props.ObraItem && <p className='obras__cont--item'>{props.ObraItem}</p>}
        {props.ObraDet && <p className='obras__cont--det'> {props.ObraDet} </p>}
      
    </div>
  )
}

export default ObraThumb