import React, { useState, useEffect } from 'react'
import Loading from '../../Commons/Loading/Loading';
import FooterFormulario from '../../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import DivisionBanner from '../../DivisionContainer/DivisionBanner/DivisionBanner';
import { Modal } from 'react-bootstrap'

import '../../../Styles/main.scss'
import '../DivisionesDetalles.scss'

const MontajeDeEnvolvente = () => {

    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = (paramUrl, paramDesc) => {

        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)

        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    })

    if (loading) { return <Loading /> }

    return (

        <section className='container-fluid p-0 containerBlock' >


            <Modal
                show={show}
                size='lg'
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Cañerias y accesorios</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
                </Modal.Body>

                <Modal.Footer>
                    <small >{txtDes}</small>
                </Modal.Footer>
            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-montaje-de-envolvente.jpg"
                    alt="Still Pipe"
                    loading='lazy'
                    className='divisionItem__container--img'
                />

                <div className='divisionItem__container--textos divisionItem__container--textos-2-lineas' >
                    <h3 className='divisionItem__container--header'>DIVISION</h3>
                    <h1 className='divisionItem__container--title'>CONSTRUCCION Y<br />REPARACION DE TANQUES</h1>
                </div>

            </div>

            <div className='container-fluid divisionItem__detail'>
                <div className='container animated fast fadeIn'>
                    <Link to={'/construccion-reparacion-tanques/'}>
                        <small>
                            <i className="fas fa-angle-double-left" style={{ 'paddingBottom': '15px', 'paddingRight': '10px' }} ></i>
                            Volver a Division</small>
                    </Link>

                    <div className='row'>
                        <div className='col-md-6'>

                            <img
                                src="../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-00.jpg"
                                alt="Se realizan tubos para medición, sondeo manual y radar.."
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            />
                        </div>

                        <div className='col-md-6 divisionItem__detail--block'>
                            <h1 className='divisionItem__detail--title' >Montaje de envolvente</h1>
                            <p className='divisionItem__detail--text' >
                                Desarrollamos reparaciones y construcciones de envolventes (pared) en tanques. Independientemente de su capacidad, elaboramos el diseño, construcción y montaje en toda su extensión. El diseño y materializado hace énfasis en un correcto rolado y posterior soldado, garantizando de esta manera una estructura segura.
                                <br /><br />
                                Todos los trabajos de soldadura son inspeccionados siguiendo el lineamiento del Plan de Inspección y ensayos particular de cada tanque.
                            </p>

                        </div>

                    </div>

                    <div className='gallery__cont'>

                        <div className='gallery__cont--column'>

                            <img onClick={() => handleShow('../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-02.jpg', 'Reparaciones y construcciones de envolventes (pared) en tanques.')} src='../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-02.jpg' alt=' reparaciones y construcciones de envolventes (pared) en tanques.' loading='lazy' />
                            <img onClick={() => handleShow('../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-03.jpg', 'Reparaciones y construcciones de envolventes (pared) en tanques.')} src='../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-03.jpg' alt=' reparaciones y construcciones de envolventes (pared) en tanques.' loading='lazy' />
                            <img onClick={() => handleShow('../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-04.jpg', 'Reparaciones y construcciones de envolventes (pared) en tanques.')} src='../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-04.jpg' alt=' reparaciones y construcciones de envolventes (pared) en tanques.' loading='lazy' />
                            <img onClick={() => handleShow('../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-05.jpg', 'Reparaciones y construcciones de envolventes (pared) en tanques.')} src='../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-05.jpg' alt=' reparaciones y construcciones de envolventes (pared) en tanques.' loading='lazy' />

                        </div>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-10.jpg', 'Reparaciones y construcciones de envolventes (pared) en tanques.')} src='../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-10.jpg' alt=' reparaciones y construcciones de envolventes (pared) en tanques.' loading='lazy' />
                            <img onClick={() => handleShow('../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-09.jpg', 'Reparaciones y construcciones de envolventes (pared) en tanques.')} src='../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-09.jpg' alt=' reparaciones y construcciones de envolventes (pared) en tanques.' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>

                            <img onClick={() => handleShow('../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-08.jpg', 'Reparaciones y construcciones de envolventes (pared) en tanques.')} src='../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-08.jpg' alt=' reparaciones y construcciones de envolventes (pared) en tanques.' loading='lazy' />
                            <img onClick={() => handleShow('../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-07.jpg', 'Reparaciones y construcciones de envolventes (pared) en tanques.')} src='../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-07.jpg' alt=' reparaciones y construcciones de envolventes (pared) en tanques.' loading='lazy' />
                        </div>


                        <div className='gallery__cont--column'>

                            <img onClick={() => handleShow('../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-11.jpg', 'Reparaciones y construcciones de envolventes (pared) en tanques.')} src='../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-11.jpg' alt=' reparaciones y construcciones de envolventes (pared) en tanques.' loading='lazy' />
                            <img onClick={() => handleShow('../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-12.jpg', 'Reparaciones y construcciones de envolventes (pared) en tanques.')} src='../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-12.jpg' alt=' reparaciones y construcciones de envolventes (pared) en tanques.' loading='lazy' />
                            
                        </div>

                        <div className='gallery__cont--column'>
                            <img onClick={() => handleShow('../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-06.jpg', 'Reparaciones y construcciones de envolventes (pared) en tanques.')} src='../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-06.jpg' alt=' reparaciones y construcciones de envolventes (pared) en tanques.' loading='lazy' />
                            <img onClick={() => handleShow('../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-13.jpg', 'Reparaciones y construcciones de envolventes (pared) en tanques.')} src='../images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-13.jpg' alt=' reparaciones y construcciones de envolventes (pared) en tanques.' loading='lazy' />

                        </div>


                    </div>
                </div>
            </div>

            <div>

                <h3 className='divisionItem__bajada--tit text-center'>
                    Otras Divisiones
                </h3>

                <div className='row divisionItem__proyectosDestacados p-0'>
                    <DivisionBanner
                        division={'Arenado y pintura'}
                        imgUrl={'../images/divisiones/division-banners/divisionBanners-arenado-y-pintura.jpg'}
                        linkUrl={'/construccion-reparacion-tanques/arenado-pintura/'}
                    />
                    <DivisionBanner
                        division={'Piso: Reparación y Construcción'}
                        imgUrl={'../images/divisiones/division-banners/divisionBanners-piso-reparacion.jpg'}
                        linkUrl={'/construccion-reparacion-tanques/piso-reparacion-y-construccion/'}
                    />
                    <DivisionBanner
                        division={'Techo: Reparación y Construcción'}
                        imgUrl={'../images/divisiones/division-banners/divisionBanners-techo-reparacion.jpg'}
                        linkUrl={'/construccion-reparacion-tanques/techo-reparacion-y-construccion/'}
                    />
                    <DivisionBanner
                        division={'Still Pipe '}
                        imgUrl={'../images/divisiones/division-banners/divisionBanners-still-pipe.jpg'}
                        linkUrl={'/construccion-reparacion-tanques/still-pipe/'}
                    />
                </div>

            </div>

            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default MontajeDeEnvolvente