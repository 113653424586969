import React, {useState, useEffect} from 'react';
import DivisionThumb from './DivisionThumb/DivisionThumb'
import DivisionContainerInternas from '../DivisionContainer/DivisionContainerInternas'
import Loading from '../Commons/Loading/Loading';
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'

import './Divisiones.scss';

const ReparacionDeTanques = () => {

    const [loading, setLoading] = useState(true);

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return    <section className='container-fluid p-0 pb-0 display-block'>
                
                <div className='division__container division__container--xl animated fast fadeIn'>
                   
                    <img 
                        src="../images/headers/headers-reparacion-de-tanques.jpg" 
                        alt="Reparacion de Tanques." 
                        loading='lazy'
                        className='division__container--img division__container--imgXl'
                    />

                    <div className='division__container--textos division__container--textos' >
                        <h3 className='division__container--header'>DIVISION</h3>
                        <h1 className='division__container--title'>Construccion y<br />reparacion de Tanques</h1>
                        <p className='division__container--text division__container--textFix' style={{textAlign: 'justify'}}>
                        Desarrollamos actividades que tienen un alcance a la inspección, diseño, construcción, alteración y reconstrucción de tanques para la industria del petróleo basados en normas internacionales como las API 12D, API 650 y API 653. Diseñamos, construimos y montamos cada elemento necesario para satisfacer las necesidades del cliente teniendo en cuenta además los factores técnicos para asegurar una estructura correcta y segura.<br /><br />
                        Nuestros proyectos abarcan tanques tanto de bajo volumen de almacenaje como de gran capacidad, superando los 10.000 m3 hasta 50.000 m3, construcciones “in situ” y en base para posterior montaje en obra.<br /><br />
                        Contamos con personal altamente capacitado y todas las herramientas necesarias para llevar a cabo las tareas con calidad y seguridad.

                        </p>
                    </div>

                </div>

                <div className='container container__divisiones'>
                    
                    <div className='text-center division__bajada--down' >
                        <i className="fas fa-angle-down fa-3x"></i>
                    </div>
                    
                    <div className='row justify-content-center proyecto__containers'>
                            <DivisionThumb 
                                imgUrl ={'./images/divisiones/construccion-reparacion-tanques/montaje-de-envolvente/montaje-de-envolvente-00.jpg'}
                                titulo ={'Montaje de envolvente'}
                                linkUrl = {'/construccion-reparacion-tanques/montaje-de-envolvente'}
                            />
                            <DivisionThumb 
                                imgUrl ={'./images/divisiones/construccion-reparacion-tanques/thumb.jpg'}
                                titulo ={'Arenado y pintura'}
                                linkUrl = {'/construccion-reparacion-tanques/arenado-pintura'}
                            />
                            <DivisionThumb 
                                imgUrl ={'./images/divisiones/construccion-reparacion-tanques/techo-reparacion-construccion/techo-00.jpg'}
                                titulo ={'Techo: Reparación y Construcción'}
                                linkUrl = {'/construccion-reparacion-tanques/techo-reparacion-y-construccion'}
                            />
                             <DivisionThumb 
                                imgUrl ={'./images/divisiones/construccion-reparacion-tanques/piso-reparacion-construccion.jpg'}
                                titulo ={'Piso: Reparación y Construcción'}
                                linkUrl = {'/construccion-reparacion-tanques/piso-reparacion-y-construccion'}
                            />
                             <DivisionThumb 
                                imgUrl ={'./images/divisiones/construccion-reparacion-tanques/pead.jpg'}
                                titulo ={'P.E.A.D'}
                                linkUrl = {'/construccion-reparacion-tanques/pead'}
                            />
                             <DivisionThumb 
                                imgUrl ={'./images/divisiones/construccion-reparacion-tanques/still-pipe.jpg'}
                                titulo ={'Still Pipe'}
                                linkUrl = {'/construccion-reparacion-tanques/still-pipe'}
                            />
                             <DivisionThumb 
                                imgUrl ={'./images/divisiones/construccion-reparacion-tanques/canerias-y-accesorios.jpg'}
                                titulo ={'Cañerias y Accesorios'}
                                linkUrl = {'/construccion-reparacion-tanques/canerias-y-accesorios'}
                            />
                    </div>

                    <h3 className='division__bajada--tit text-center'>
                        Otras Obras
                    </h3>
                    <div>
                        <DivisionContainerInternas divSelect={'reparacion-tanques'} />
                    </div>

                </div>

                <FooterFormulario className='mt-5' />
            </section>

};

export default ReparacionDeTanques;
