import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap'
import './Carousel.scss';
import LoadingCarousel from '../Loading/LoadingCarousel'

const CarouselContainer = () => {

    const [loading, setLoading] = useState(true);

    useEffect( () => {

        setTimeout(()=>{
            setLoading(false)
        }, 2000)
    }, [])

    if(loading) {return <LoadingCarousel />}
    
    return (
        <section className='container-fluid p-0 mb-4 animated fast fadeIn'>
            <Carousel className='carousel__box' >

                <Carousel.Item>
                    <img
                    className="d-block w-100 carousel__image"
                    src="../images/carousel/carousel-imagen-1.jpg"
                    alt="Armado de Tanques"
                    loading="lazy"
                    />
                    <Carousel.Caption>
                        <img src='../images/carousel/logo-construcciones-integrales.png' 
                             className='carouselLogo carouselLogo--entraDer' 
                             alt="Logo de Construcciones Integrales, mas titulo."
                             loading="lazy"
                        />
                        <h1 className='carousel__h1 carousel__h1-entraIzq  text-center'>
                            Ingenieria,<br />Obras y Servicios
                        </h1>
                    </Carousel.Caption>
                </Carousel.Item>
                
                <Carousel.Item >
                    <img
                    className="d-block w-100 carousel__image"
                    src="../images/carousel/carousel-imagen-2.jpg"
                    alt="Alquiler y armado de andamos de gran escala."
                    loading="lazy"
                    />
                    <Carousel.Caption>
                        <img 
                            src='../images/carousel/logo-construcciones-integrales.png'  
                            loading="lazy"
                            className='carouselLogo carouselLogo--entraIzq' 
                            alt="Logo de Construcciones Integrales, mas titulo." />

                        <h1 className='carousel__h1 carousel__h1-entraDer  text-center'>
                        Armado<br />de andamios</h1>
                    </Carousel.Caption>
                </Carousel.Item>
                
                <Carousel.Item >
                    <img
                    className="d-block w-100 carousel__image"
                    src="../images/carousel/carousel-aislacion-h3.jpg"
                    alt="Reparacion de tanques."
                    loading="lazy"
                    />
                    <Carousel.Caption>
                        <img 
                            src='../images/carousel/logo-construcciones-integrales.png'  
                            loading="lazy"
                            className='carouselLogo carouselLogo--entraIzq' 
                            alt="Logo de Construcciones Integrales, mas titulo."
                        />
                        <h1 className='carousel__h1 carousel__h1-entraDer text-center'>Aislaciones<br />Termicas</h1>
                    </Carousel.Caption>
                </Carousel.Item>
                
                <Carousel.Item >
                    <img
                    className="d-block w-100 carousel__image"
                    src="../images/carousel/carousel-imagen-3.jpg"
                    alt="Third slide"
                    loading="lazy"
                    />
                    <Carousel.Caption>
                        <img src='../images/carousel/logo-construcciones-integrales.png' loading="lazy"
                            className='carouselLogo carouselLogo--entraIzq' alt="Logo de Construcciones Integrales, mas titulo." />
                        <h1 className='carousel__h1 carousel__h1-entraDer text-center'>Reparación de<br />Tanques
                        </h1>  
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item >    
                    <img
                    className="d-block w-100 carousel__image"
                    src="../images/carousel/carousel-imagen-tanques.jpg"
                    alt="Construcción y reparación de Tanques."
                    loading="lazy"
                    />
                    <Carousel.Caption>
                        <img src='../images/carousel/logo-construcciones-integrales.png' loading="lazy"
                            className='carouselLogo carouselLogo--entraIzq' alt="Logo de Construcciones Integrales, mas titulo." />
                        <h1 className='carousel__h1 carousel__h1-entraDer text-center carousel__h1--telecomunicaciones '>Construcción y reparación de Tanques
                        </h1>  
                    </Carousel.Caption>
                </Carousel.Item>
                
                <Carousel.Item >
                    <img
                    className="d-block w-100 carousel__image"
                    src="../images/carousel/carousel-estrucutra-tanque.jpg"
                    alt="Reparacion de tanques."
                    loading="lazy"
                    />
                    <Carousel.Caption>
                        <img 
                            src='../images/carousel/logo-construcciones-integrales.png'  
                            loading="lazy"
                            className='carouselLogo carouselLogo--entraIzq' 
                            alt="Logo de Construcciones Integrales, mas titulo." />

                        <h1 className='carousel__h1 carousel__h1-entraDer  text-center'>
                        Armado<br />de andamios</h1>
                    </Carousel.Caption>
                </Carousel.Item>
                
                <Carousel.Item >
                    <img
                    className="d-block w-100 carousel__image"
                    src="../images/carousel/carousel-recubrimiento-chapa.jpg"
                    alt="Trabajo de recubrimiento en chapa."
                    loading="lazy"
                    />
                    <Carousel.Caption>
                        <img src='../images/carousel/logo-construcciones-integrales.png' loading="lazy"
                            className='carouselLogo carouselLogo--entraIzq' alt="Logo de Construcciones Integrales, mas titulo." />
                        <h1 className='carousel__h1 carousel__h1-entraDer text-center'>Aislaciones<br />Termicas
                        </h1>  
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item >    
                    <img
                    className="d-block w-100 carousel__image"
                    src="../images/carousel/carousel-construccion-tanque.jpg"
                    alt="Construcción y reparación de Tanques."
                    loading="lazy"
                    />
                    <Carousel.Caption>
                        <img src='../images/carousel/logo-construcciones-integrales.png' loading="lazy"
                            className='carouselLogo carouselLogo--entraIzq' alt="Logo de Construcciones Integrales, mas titulo." />
                        <h1 className='carousel__h1 carousel__h1-entraDer text-center carousel__h1--telecomunicaciones '>Construcción y reparación de Tanques
                        </h1>  
                    </Carousel.Caption>
                </Carousel.Item>

                
            </Carousel>
        </section>
    )
}

export default CarouselContainer
