import React, {useState, useEffect} from 'react'
import Loading from '../../Commons/Loading/Loading';
import FooterFormulario from '../../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import DivisionBanner from '../../DivisionContainer/DivisionBanner/DivisionBanner';
import { Modal } from 'react-bootstrap'

import '../../../Styles/main.scss'
import '../DivisionesDetalles.scss'

const Networking = () => {

    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (paramUrl, paramDesc) => {
        
        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)
        
        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return (

        <section className='container-fluid p-0 containerBlock' > 


            <Modal
              show={show}
              size='lg'
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Soluciones de alta performance</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
              </Modal.Body>

              <Modal.Footer>
                <small >{txtDes}</small>
              </Modal.Footer>
            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-networking.jpg" 
                    alt="Soluciones de alta performance ." 
                    loading='lazy'
                    className='divisionItem__container--img'    
                />

                <div className='divisionItem__container--textos divisionItem__container--textos-2-lineas' >
                    <h3 className='divisionItem__container--header'>DIVISION</h3>
                    <h1 className='divisionItem__container--title'>TELECOMUNICACIONES</h1>
                </div>

            </div>

            <div className='container-fluid divisionItem__detail'>
                <div className='container animated fast fadeIn'>
                <Link to={'/telecomunicaciones/'}>
                <small> 
                    <i className="fas fa-angle-double-left" style={{'paddingBottom':'15px', 'paddingRight': '10px'}} ></i> 
                    Volver a Division</small>
                </Link>

                <div className='row mb-5'>
                    <div className='col-md-6'> 
                        
                        <img 
                            src="../images/divisiones/telecomunicaciones/networking/imagen-01.jpg" 
                            alt="Soluciones de alta performance."
                            className='d-block w-100 divisionItem__detail--header'
                            loading='lazy'
                        /> 
                    </div>

                    <div className='col-md-6 divisionItem__detail--block'>
                        <h1 className='divisionItem__detail--title' >Networking</h1>
                        <p  className='divisionItem__detail--text' > 
                        Brindamos soluciones de gran calidad en el servicio de cableado estructurado 
                        que nos mantiene como referentes dentro de la región. Tenemos a disposición 
                        la tecnología en equipamientos certificados para realizar la tarea.<br /><br />
                        </p> 
                    </div>

                </div>

                <div className='gallery__cont'>

                    <div className='gallery__cont--column'>
                        <img    onClick={ () => handleShow('../images/divisiones/telecomunicaciones/networking/imagen-01.jpg', 'Soluciones de alta performance ') } src='../images/divisiones/telecomunicaciones/networking/imagen-01.jpg' alt='Soluciones de alta performance .' loading='lazy'/>
                        <img    onClick={ () => handleShow('../images/divisiones/telecomunicaciones/networking/imagen-02.jpg', 'Soluciones de alta performance ') } src='../images/divisiones/telecomunicaciones/networking/imagen-02.jpg' alt='Soluciones de alta performance .' loading='lazy' />
                       
                    </div>

                    <div className='gallery__cont--column'>
                        <img    onClick={ () => handleShow('../images/divisiones/telecomunicaciones/networking/imagen-03.jpg', 'Soluciones de alta performance ') } src='../images/divisiones/telecomunicaciones/networking/imagen-03.jpg' alt='Soluciones de alta performance .' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/telecomunicaciones/networking/imagen-07.jpg', 'Soluciones de alta performance ') } src='../images/divisiones/telecomunicaciones/networking/imagen-07.jpg' alt='Soluciones de alta performance .' loading='lazy' />
                       
                    </div>

                    <div className='gallery__cont--column'>
                        
                        <img    onClick={ () => handleShow('../images/divisiones/telecomunicaciones/networking/imagen-05.jpg', 'Soluciones de alta performance ') } src='../images/divisiones/telecomunicaciones/networking/imagen-05.jpg' alt='Soluciones de alta performance .' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/telecomunicaciones/networking/imagen-06.jpg', 'Soluciones de alta performance ') } src='../images/divisiones/telecomunicaciones/networking/imagen-06.jpg' alt='Soluciones de alta performance .' loading='lazy' /> 
                       
                    </div>

                    <div className='gallery__cont--column'>
                        <img    onClick={ () => handleShow('../images/divisiones/telecomunicaciones/networking/imagen-04.jpg', 'Soluciones de alta performance ') } src='../images/divisiones/telecomunicaciones/networking/imagen-04.jpg' alt='Soluciones de alta performance .' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/telecomunicaciones/networking/imagen-08.jpg', 'Soluciones de alta performance ') } src='../images/divisiones/telecomunicaciones/networking/imagen-08.jpg' alt='Soluciones de alta performance .' loading='lazy' /> 
                    </div>

                    <div className='gallery__cont--column'>
                       
                        <img    onClick={ () => handleShow('../images/divisiones/telecomunicaciones/networking/imagen-09.jpg', 'Soluciones de alta performance ') } src='../images/divisiones/telecomunicaciones/networking/imagen-09.jpg' alt='Soluciones de alta performance .' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/telecomunicaciones/networking/imagen-10.jpg', 'Soluciones de alta performance ') } src='../images/divisiones/telecomunicaciones/networking/imagen-10.jpg' alt='Soluciones de alta performance .' loading='lazy' />
                        
                    </div> 
                
                </div>
                </div>
            </div>

            <div>

                <h3 className='divisionItem__bajada--tit text-center'>
                    Otras Tareas
                </h3>

                <div className='row divisionItem__proyectosDestacados p-0'> 
                    <DivisionBanner 
                        division = {'Dts y Das'}
                        imgUrl = {'../images/divisiones/telecomunicaciones/dts-y-das.jpg'}
                        linkUrl = {'/telecomunicaciones/dts-das/'}
                    /> 
                   <DivisionBanner 
                        division = {'FTTx'}
                        imgUrl = {'../images/divisiones/telecomunicaciones/fttx.jpg'}
                        linkUrl = {'/telecomunicaciones/fttx/'}
                    /> 
                    <DivisionBanner 
                        division = {'Fibra Optica'}
                        imgUrl = {'../images/divisiones/telecomunicaciones/fibra-optica.jpg'}
                        linkUrl = {'/telecomunicaciones/fibra-optica/'}
                    /> 
                    <DivisionBanner 
                        division = {'Video Vigilancia'}
                        imgUrl = {'../images/divisiones/telecomunicaciones/videovigilancia-domotica.jpg'}
                        linkUrl = {'/telecomunicaciones/video-vigilancia/'}
                    /> 
                </div>

            </div>
            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default Networking